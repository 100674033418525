import VExpansionPanels from './VExpansionPanels'
import VExpansionPanel from './VExpansionPanel'
import VExpansionPanelContent from './VExpansionPanelContent'
import VExpansionPanelHeader from './VExpansionPanelHeader'

export { VExpansionPanels, VExpansionPanel, VExpansionPanelHeader, VExpansionPanelContent }

export default {
  $_vuetify_subcomponents: {
    VExpansionPanels,
    VExpansionPanel,
    VExpansionPanelHeader,
    VExpansionPanelContent,
  },
}
