export { dataCodeName };

const dataCodeName = Object.freeze({
	// returnZipCode: '우편번호',
	// returnAddr: '기본주소',
	// returnAddrDetail: '상세주소',
	// username: '계정명',
	// password: '비밀번호',
	// oriPw: '현재비밀번호',
	// newPw1: '새 비밀번호',
	// newPw2: '새 비밀번호 확인',
	// currentPwd: '현재 비밀번호',
	// phone: '연락처',
	// phone1: '연락처 앞자리',
	// phone2: '연락처 중간자리',
	// phone3: '연락처 뒷자리',
	// roleIds: '역할',
	// name: '사용자명',
	// email: '이메일',
	// goodsName: '상품명',
	// modelName: '모델명',
	// network: '통신망',
	// maker: '제조사',
	// capacity: '용량',
	// chargeAmt: '요금',
	// chargeName: '요금제명',
	// category: '카테고리',
	// addSvcCharge: '요금',
	// chargerName: '담당자명',
	// chargerEmail: '담당자 이메일',
	// chargeId: '요금제',
	// goodsId: '기기',
	// releaseAmt: '출고가',
	// supportAmt: '공시지원금',
	// telecom: '통신사',
	// orderDate: '주문일',
	// makerName: '제조사',
	// colorName: '색상',
	// cusName: '고객명',
	// cusPhone: '고객 전화번호',
	// zipCode: '우편번호',
	// addr2: '상세주소2',
	// courier: '택배사',
	// invoiceNo: '송장번호',
	// cusPhone1: '휴대폰번호 또는 연락처 앞자리',
	// cusPhone2: '휴대폰번호 또는 연락처 중간자리',
	// cusPhone3: '휴대폰번호 또는 연락처 뒷자리',
	// chargerPhone1: '연락처 앞자리',
	// chargerPhone2: '연락처 중간자리',
	// chargerPhone3: '연락처 뒷자리',
	// fileData: '첨부파일',
	// delYn: '삭제여부',
	// regiDatetime: '등록일시',
	// updDateTime: '수정일시',
	// // 고객정보
	// cusRegiNum1: '주민등록번호 앞자리',
	// cusRegiNum2: '주민등록번호 뒷자리',
	// cusZipCode: '고객우편번호',
	// cusAddr: '고객기본주소',
	// cusAddrDetail: '고객상세주소',
	// cusType: '고객유형',
	// // 가입정보
	// openingPhone1: '개통휴대폰번호',
	// openingPhone2: '개통휴대폰번호',
	// openingPhone3: '개통휴대폰번호',
	// openingHopeNum: '개통희망번호',
	// openingTelecomCodeId: '개통통신사',
	// existTelecomCodeId: '기존통신사',
	// joinType: '번호이동',
	// agreementType: '약정유형',
	// agreementPeriodType: '약정기간',
	// instlPeriodType: '할부기간',
	// monthDvcAmt: '월기기금액',
	// monthBasicAmt: '월기본료',
	// monthPaymentAmt: '월납부금액',
	// color: '색상',
	// pubNotiSupportAmt: '공시지원금',
	// extraSupportAmt: '추가지원금',
	// freeInstlAmt: '프리할부/선수납',
	// marginAmt: '기타할인금',
	// actualSellAmt: '실판매가',
	// depositYn: '입금여부',
	// // 배송정보
	// deliveryType: '배송구분',
	// courierCodeId: '택배사',
	// invoiceNum: '송장번호',
	// deliveryZipCode: '배송 우편번호',
	// deliveryAddr: '배송 기본주소',
	// deliveryAddrDetail: '배송 상세주소',
	// // 상담원
	// consultUserId: '상담원',
	// giftEnclosedYn: '사은품동봉여부',
	// giftName: '사은품',
	// openingTelecomName: '개통통신사',
	cusName: '이름',
	cusPhone: '연락처',
	cusPhone1: '연락처 앞자리',
	cusPhone2: '연락처 중간자리',
	cusPhone3: '연락처 뒷자리',
	cusEmail: '이메일',
	cusRegiNum1: '생년월일',
	cusZipCode: '우편번호',
	cusAddr: '기본주소',
	cusAddrDetail: '상세주소',
	deliveryZipCode: '우편번호',
	deliveryAddr: '기본주소',
	deliveryAddrDetail: '상세주소',
	blogUrl: '후기링크',
	username: '아이디',
	password: '비밀번호',
	loginId: '이메일(ID)',
	loginPassword: '비밀번호',
	confirmLoginPassword: '비민번호',
	memberName: '이름',
	memberBirth: '생년월일',
	memberPhone: '연락처',
	memberPhone1: '연락처 앞자리',
	memberPhone2: '연락처 중간자리',
	memberPhone3: '연락처 뒷자리',
	memberZipcode: '우편번호',
	memberAddr: '주소',
	memberAddrDetail: '상세주소',
	openingHopeNum: '개통 희망 번호',
	numChangeAuthType: '번호 이동 인증 방법',
	numChangeAuthTypeNum: '번호 이동 인증 번호',
	openingPhone: '개통 휴대폰번호',
	openingPhone1: '개통 휴대폰번호 앞자리',
	openingPhone2: '개통 휴대폰번호 중간자리',
	openingPhone3: '개통 휴대폰번호 뒷자리',
});
