import Vue from 'vue';
import Vuex from 'vuex';
import {
	getStoreMallReviewEvent,
	insertMallReview,
	validateUrl,
	getMallReviewList,
} from '@/api/mall/EventReviewApi';

Vue.use(Vuex);

const ProductDetailModule = {
	namespaced: true,
	state: {
		productBottomContMode: 'INFO',
	},
	mutations: {
		setSample(state, result) {
			// sample Set
		},
		setSampleInit(state) {
			// sample Init
		},
	},
	actions: {
		async getStoreMallReviewEvent({ commit }, data) {
			try {
				const result = await getStoreMallReviewEvent(data);
				if (result && '0000' === result.data.resultCode) {
					return result.data.data;
				} else {
					return false;
				}
			} catch (e) {
				console.log(e);
			}
		},
		async validateUrl({ commit }, data) {
			try {
				const result = await validateUrl(data);
				if (result && '0000' === result.data.resultCode) {
					return true;
				} else if (result && '0000' !== result.data.resultCode) {
					alert(result.data.resultMsg);
					return false;
				}
			} catch (e) {
				console.log(e);
			}
		},
		async insertMallReview({ commit }, data) {
			try {
				const result = await insertMallReview(data);
				if (result && '0000' === result.data.resultCode) {
					return true;
				} else {
					return false;
				}
			} catch (e) {
				console.log(e);
			}
		},
		async getMallReviewList({ commit }, data) { // 리뷰 목록 조회(메인)
			try {
				const result = await getMallReviewList(data);
				if (result && '0000' === result.data.resultCode) {
					return result.data.data;
				} else {
					return false;
				}
			} catch (e) {
				console.log(e);
			}
		},
	},
};
export default ProductDetailModule;
