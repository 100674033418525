// 전체 쿠키 저장 (param = state.cookies)
// params
// getData = Login Result
// cookies = state.cookies
function saveCookies(getData, cookies) {
	for (let [key, value] of Object.entries(getData)) {
		if (value && key !== 'mp') {
			cookies[key] = value || '';

			let userAgent = navigator.userAgent.toLowerCase();
			if (
				userAgent.indexOf('iphone') > -1 ||
				userAgent.indexOf('ipad') > -1 ||
				userAgent.indexOf('ipod') > -1
			) {
				//IOS
				value = escape(value);
			}
			document.cookie = `${key}=${value};path=/`;
		} else if (key === 'mp') {
			localStorage.setItem(key, value);
		} else {
			return;
		}
	}
}

// 쿠키 단일 값 조회 (param key = 쿠키 Name)
function getFromCookie(key) {
	const regex = new RegExp(
		'(?:(?:^|.*;\\s*)' + key + '\\s*=\\s*([^;]*).*$)|^.*$',
	);

	let userAgent = navigator.userAgent.toLowerCase();
	if (
		userAgent.indexOf('iphone') > -1 ||
		userAgent.indexOf('ipad') > -1 ||
		userAgent.indexOf('ipod') > -1
	) {
		//IOS
		return unescape(document.cookie.replace(regex, '$1'));
	} else {
		return document.cookie.replace(regex, '$1');
	}
}

// 전체 쿠키 삭제 (param object = state.cookies)
function deleteCookies(object) {
	let cookies = document.cookie.split(';');
	for (let i = 0; i < cookies.length; i++) {
		let cookie = cookies[i];
		let eqPos = cookie.indexOf('=');
		let name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
		document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/';
	}
	/*
  for (let [key] of Object.entries(object)) {
    document.cookie = `${key}=; expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
  }*/
}

export { saveCookies, getFromCookie, deleteCookies };
