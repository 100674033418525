import { instance, instanceNS, instanceAdmin } from '../index.js';
import { jsonToQueryString } from '../../common/common';

const domain = 'ApplicationManagement/ApplicationMgmt/';

// 로그아웃
const insertApplication = async data => {
	const response = await instance.post(`${domain}insertApplication`, data);
	return response;
};

// 기타 문의 등록 api
const insertStoreCustomer = async data => {
	const response = await instanceAdmin.post(
		`SalesManagement/ConsultMgmt/insertStoreCustomer`,
		data,
	);
	return response;
};

// 주문 상세 조회
const getApplicationDetail = async data => {
	let param = '';
	if (data) param = jsonToQueryString(data);
	const response = await instance.get(`${domain}getApplicationDetail${param}`);
	return response;
};

// 주문 상세 페이지 리뷰조회
const getMyMallReview = async data => {
	let param = '';
	if (data) param = jsonToQueryString(data);
	const response = await instance.get(
		`MallManagement/MallReview/getMyMallReview${param}`,
	);
	return response;
};

// 주문내역 리뷰이벤트 진행여부
const getReviewEventCheck = async data => {
	let param = '';
	if (data) param = jsonToQueryString(data);
	const response = await instance.get(
		`MallManagement/MallEvent/getReviewEventCheck${param}`,
	);
	return response;
};

// 주문내역 목록 조회
const getApplicationList = async data => {
	let param = '';
	if (data) param = jsonToQueryString(data);
	const response = await instance.get(`${domain}getApplicationList${param}`);
	return response;
};

// 상품 상세조회
const getGoodsDetail = async data => {
	let param = '';
	if (data) param = jsonToQueryString(data);
	const response = await instance.get(
		`/MallManagement/GoodsMgmt/getGoodsDetail${param}`,
	);
	return response;
};

/*
// 공시지원금 조회
const getPubNotiInfo = async data => {
	let param = '';
	if (data) param = jsonToQueryString(data);
	const response = await instance.get(
		`MallManagement/PubNotiMgmt/getPubNotiInfo${param}`,
	);
	return response;
};
*/

// 요금제 조회
const getTelecomChargeList = async data => {
	const response = await instance.get(
		`MallManagement/ChargeMgmt/getTelecomChargeList/${data}`,
	);
	return response;
};

// 주문취소
const cancelApplication = async data => {
	const response = await instance.post(
		`ApplicationManagement/ApplicationMgmt/cancelApplication?orderNum=${data}`,
	);
	return response;
};

export {
	insertApplication,
	insertStoreCustomer,
	getApplicationDetail,
	getMyMallReview,
	getReviewEventCheck,
	getApplicationList,
	getGoodsDetail,
	// getPubNotiInfo,
	getTelecomChargeList,
	cancelApplication,
};
