import Vue from 'vue';
import Vuex from 'vuex';
import {
	getStoreMallBannerList,
} from '@/api/mall/BannerApi';

Vue.use(Vuex);

const BannerModule = {
	namespaced: true,
	state: {
	},
	mutations: {
		setSample(state, result) {
			// sample Set
		},
		setSampleInit(state) {
			// sample Init
		},
	},
	actions: {
		async getStoreMallBannerList({ commit }, data) { // 리뷰 목록 조회(메인)
			try {
				const result = await getStoreMallBannerList(data);
				if (result && '0000' === result.data.resultCode) {
					return result.data.data;
				} else {
					return false;
				}
			} catch (e) {
				console.log(e);
			}
		},
	},
};
export default BannerModule;
