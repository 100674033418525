import { render, staticRenderFns } from "./TelecomModal.vue?vue&type=template&id=50f51044&scoped=true&"
import script from "./TelecomModal.vue?vue&type=script&lang=js&"
export * from "./TelecomModal.vue?vue&type=script&lang=js&"
import style0 from "./TelecomModal.vue?vue&type=style&index=0&id=50f51044&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "50f51044",
  null
  
)

export default component.exports