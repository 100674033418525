<template>
	<div class="float-right drawer-content2 justify-end">
		<div>
			<div class="disFx justify-center"
					 :class="nowTelecom === 1 ? 'groupBoxSave' : 'groupBox'"
					 @click="dialogTelecom = true;">
				<div class="telImg" v-if="nowTelecom === 5">
					<img src="../../src/assets/img/logo_sk.png" alt="SKT" />
				</div>
				<div class="telImg" v-else-if="nowTelecom === 6">
					<img src="../../src/assets/img/logo_kt.png" alt="KT" />
				</div>
				<div class="telImg" v-else-if="nowTelecom === 7">
					<img src="../../src/assets/img/logo_lg.png" alt="LGU" />
				</div>
				<div class="telImg imgSave" v-else-if="nowTelecom === 1">
					<img src="../../src/assets/img/logo_save1.png" alt="SAVE" />
				</div>
				<div class="telImg" v-else>
					<img src="../../src/assets/img/logo_new.png" alt="NEW" />
				</div>
			</div>
			<div class="groupBox">
				<a :href="mallPhoneCounselNum">
					<div class="disFx justify-center">
						<i class="fas fa-phone text" style="font-size: 20px;"></i>
					</div>
					<div>전화상담</div>
				</a>
			</div>
		</div>
	</div>
</template>

<script>

export default {
	computed: {
		nowTelecom: {
			get() {
				return this.$store.state.commonModule.nowTelecom;
			},
			set(newValue) {
				this.$store.state.commonModule.nowTelecom = newValue;
			},
		},
		dialogTelecom: {
			get() {
				return this.$store.state.commonModule.telecomModalActive;
			},
			set(newValue) {
				this.$store.state.commonModule.telecomModalActive = newValue;
			},
		},
    mallPhoneCounselNum() {
      return 'tel:' + this.$store.state.commonModule.mallPhoneCounselNum;
    }
  },
	methods: {
	},
};
</script>

<style scoped>

.drawer-content2 {
	z-index: 1;
	position: fixed;
	right: 0;
	top: 120px !important;
	left: 0 !important;
	padding: 16px 5px 15px 5px !important;
	border-top-left-radius: 20px !important;
	border-top-right-radius: 20px !important;
}
.groupBox {
	margin-bottom: 10px;
	margin-right: 10px;
	padding: 8px;
	border-radius: 20px;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
	background: #fff;
}
.groupBoxSave {
	margin-bottom: 10px;
	margin-right: 10px;
	border-radius: 20px;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
	background: #fff;
}
.groupBox .telImg img {
	width: 25px;
	height: 25px;
}
.groupBoxSave .imgSave img {
	border-radius: 10px;
	width: 45px !important;
	height: 45px !important;
}
</style>
