import Vue from 'vue';
import Vuex from 'vuex';
import { getMallLogo } from '@/api/common/common';
import { reSetInstance } from '@/api';

Vue.use(Vuex);
const commonModule = {
	namespaced: true,
	state: {
		telecomModalActive: false,
		noMemLoginModalActive: false,
		findIdModalActive: false,
		findPwdModalActive: false,
		questionModalActive: false,
		chargeModalActive: false,
		nowTelecom: 0,
		mallTermsList: [],
		mallInfo: {
			logoResponseDto: {
				mallName: '',
				domainUrl: '',
				logoImagePath: null,
			},
			infoResponseDto: {
				storeInfoId: 0,
				saleStoreId: null,
				regiDatetime: null,
				updDatetime: null,
				bizNum: '',
				ceoName: '',
				storeAddr: '',
				storeAddrDetail: '',
				storeName: '',
				storePhone: '',
				storePhone1: '',
				storePhone2: '',
				storePhone3: '',
				storeEmail: null,
				storeZipCode: '',
			},
			storeInfoResponseDto: {
				ictmarketPopUrl: '',
				mallKeywords: '',
				mallDescription: '',
				mallTitle: '',
				customerServiceTel: '',
				telecomSalesNum: '',
			},
		},
		mallPhoneCounselNum: '',
	},
	mutations: {
		setMallLogo(state, result) {
			state.mallInfo = result;
			state.mallPhoneCounselNum =
				result.infoResponseDto.storePhone.length === 8
					? result.infoResponseDto.storePhone.substring(0, 4) +
					  '-' +
					  result.infoResponseDto.storePhone.substring(4)
					: result.infoResponseDto.storePhone1 +
					  '-' +
					  result.infoResponseDto.storePhone2 +
					  '-' +
					  result.infoResponseDto.storePhone3;
		},
		setSampleInit(state) {
			// sample Init
		},
		setAuthToken(state, data) {
			if (data) {
				state.authToken = data;
			}
			if (window.sessionStorage.getItem('authToken') === null) {
				state.authToken = '';
			} else {
				state.authToken = window.sessionStorage.getItem('authToken');
				reSetInstance();
			}
		},
	},
	actions: {
		async getMallLogo({ commit }, data) {
			try {
				const result = await getMallLogo(data);
				if (result && '0000' === result.data.resultCode) {
					if (result.headers.authorization) {
						window.sessionStorage.setItem(
							'authToken',
							result.headers.authorization,
						);
						commit('setAuthToken', result.headers.authorization);
					}
					commit('setMallLogo', result.data.data);
				}
			} catch (e) {
				console.log(e);
			}
		},
	},
};

export default commonModule;
