import VAppBar from './VAppBar'
import VAppBarNavIcon from './VAppBarNavIcon'
import VAppBarTitle from './VAppBarTitle'

export { VAppBar, VAppBarNavIcon, VAppBarTitle }

export default {
  $_vuetify_subcomponents: {
    VAppBar,
    VAppBarNavIcon,
    VAppBarTitle,
  },
}
