import bus from '../../common/bus.js';
import store from '../../store/index';

export function setInterceptors(instance) {
	// Add a request interceptor
	instance.interceptors.request.use(
		function (config) {
			// 서버 배포시에만 스피너 start
			// if (process.env.NODE_ENV === 'production') {
			// 	bus.$emit('start:spinner');
			// }
			if(config.headers.Authorization === '') {
				delete config.headers.Authorization
			}
			bus.$emit('start:spinner');
			return config;
		},
		function (error) {
			// Do something with request error
			return Promise.reject(error);
		},
	);

	// Add a response interceptor
	instance.interceptors.response.use(
		function (response) {
			if (response.data.status === 200) {
				// 데이터 통신 성공시 스피너 off
				bus.$emit('end:spinner');
			}
			// 쿠키 만료시 재인증 처리
			if (response.data.status === 401) {
				if (response.data.resultCode === '9002') {
					alert(`${response.data.resultMsg}`);
					return false;
				} else {
					console.log('토큰 만료');
					store.dispatch('LOGOUT');
					// store.dispatch('LOGOUT', 'Y');
					// 토큰만료시 알 수 없는 이유로 (세션?) 전에 동작하던 api 가 호출?됨
					// 방지하기위해 로그인페이지로 리다이렉트후 새로고침
					//location.reload();
					return false;
				}
			}
			return response;
		},
		function (error) {
			if (error.response.data.message) {
				alert(error.response.data.message);
			}
			if (
				window.location.pathname.indexOf('/consult-mgmt') >= 0 &&
				error.response.data.message === '권한이 없습니다'
			) {
				history.back();
			}
			if (
				window.location.pathname === '/application-form-creation' &&
				error.response.data.error === 'BAD_REQUEST' &&
				error.response.data.status === 400
			) {
				window.location.replace(window.location.href);
			}
			bus.$emit('end:spinner');
			console.log(error);
			// Do something with request error
		},
	);
	return instance;
}

export function setInterceptorsNS(instance) {
	// Add a request interceptor
	instance.interceptors.request.use(
		function (config) {
			// 서버 배포시에만 스피너 start
			// if (process.env.NODE_ENV === 'production') {
			// 	bus.$emit('start:spinner');
			// }
			return config;
		},
		function (error) {
			console.log(error);
			// Do something with request error
			return Promise.reject(error);
		},
	);

	// Add a response interceptor
	instance.interceptors.response.use(
		function (response) {
			if (response.data.status === 200) {
				// 데이터 통신 성공시 스피너 off
			}
			// 쿠키 만료시 재인증 처리
			if (response.data.status === 401) {
				if (response.data.resultCode === '9002') {
					alert(`${response.data.resultMsg}`);
					return false;
				} else {
					console.log('토큰 만료');
					store.dispatch('LOGOUT');
					// store.dispatch('LOGOUT', 'Y');
					// 토큰만료시 알 수 없는 이유로 (세션?) 전에 동작하던 api 가 호출?됨
					// 방지하기위해 로그인페이지로 리다이렉트후 새로고침
					//location.reload();
					return false;
				}
			}
			return response;
		},
		function (error) {
			if (error.response.data.message) {
				alert(error.response.data.message);
			}
			console.log(error);
			// Do something with request error
		},
	);
	return instance;
}
