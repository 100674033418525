import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store/index';
import { scrollMainToTop } from '@/common/common';
import { logout } from '@/api/mall/MemberApi';

Vue.use(VueRouter);
export const router = new VueRouter({
	mode: 'history',
	routes: [
		{
			// index
			path: '/',
			name: 'desktopMain',
			component: () => import(`@/views/desktop/main-page/MainPage`),
		},
		{
			// index
			path: '/m',
			name: 'mobileMain',
			component: () => import(`@/views/mobile/main-page/MobMainPage`),
		},
		{
			// 로그인
			path: '/login',
			component: () => import(`@/views/desktop/member-page/Login`),
			meta: { auth: true },
		},
		{
			// 로그인
			path: '/m/login',
			component: () => import(`@/views/mobile/member-page/MobLogin`),
			meta: { auth: true },
		},

		{
			// 회원가입
			path: '/sign-up',
			component: () => import(`@/views/desktop/member-page/SignUp`),
			meta: { auth: true },
		},
		{
			// 회원가입
			path: '/m/sign-up',
			component: () => import(`@/views/mobile/member-page/MobSignUp`),
			meta: { auth: true },
		},
		{
			// 마이페이지
			path: '/mypage',
			component: () => import(`@/views/desktop/my-page/MyPage`),
			meta: { auth: true },
		},
		{
			// 마이페이지
			path: '/m/mypage',
			component: () => import(`@/views/mobile/my-page/MobMyPage`),
			meta: { auth: true },
		},
		{
			// 비밀번호 검증
			path: '/check-pwd',
			component: () => import(`@/views/desktop/single-page/CheckPassword`),
			meta: { auth: true },
		},
		{
			// 비밀번호 검증
			path: '/m/check-pwd',
			component: () => import(`@/views/mobile/single-page/MobCheckPassword`),
			meta: { auth: true },
		},
		{
			// 비밀번호 재설정
			path: '/change-pwd',
			component: () => import(`@/views/desktop/single-page/ChangePassword`),
			meta: { auth: true },
		},
		{
			// 회원정보수정
			path: '/personal-info',
			name: 'personalinfo',
			component: () => import(`@/views/desktop/member-page/PersonalInfo`),
			meta: { auth: true },
		},
		{
			// 회원정보수정
			path: '/m/personal-info',
			name: 'mpersonalinfo',
			component: () => import(`@/views/mobile/member-page/MobPersonalInfo`),
			meta: { auth: true },
		},
		{
			// 주문 상세 내역
			path: '/order-status',
			component: () => import(`@/views/desktop/my-page/OrderStatus`),

			props: true,
			meta: { auth: true },
		},
		{
			// 주문 상세 내역
			path: '/m/order-status',
			component: () => import(`@/views/mobile/my-page/MobOrderStatus`),
			props: true,
			meta: { auth: true },
		},
		{
			// 비회원 주문 상세 내역
			path: '/guest-order-status',
			name: 'guestOrderStatus',
			component: () => import(`@/views/desktop/my-page/GuestOrderStatus`),
			meta: { auth: true },
		},
		{
			// 비회원 주문 상세 내역(모바일)
			path: '/m/guest-order-status',
			name: 'guestOrderStatusMob',
			component: () => import(`@/views/mobile/my-page/MobGuestOrderStatus`),
			meta: { auth: true },
		},
		{
			// 주문 완료
			path: '/order-complete',
			name: 'ordercomplete',
			component: () => import(`@/views/desktop/product-page/OrderComplete`),
			meta: { auth: true },
		},
		{
			// 주문 완료
			path: '/m/order-complete',
			name: 'mordercomplete',
			component: () => import(`@/views/mobile/product-page/MobOrderComplete`),
			meta: { auth: true },
		},
		{
			// 기기 상세
			path: '/product',
			component: () => import(`@/views/desktop/product-page/ProductPage`),
			props: true,
		},
		{
			// 기기 상세
			path: '/m/product',
			component: () => import(`@/views/mobile/product-page/MobProductPage`),
			props: true,
		},
		{
			// 기기 주문
			path: '/order',
			component: () => import(`@/views/desktop/product-page/ProductOrderPage`),
		},
		{
			// 기기 주문
			path: '/m/order',
			component: () =>
				import(`@/views/mobile/product-page/MobProductOrderPage`),
		},
		{
			// 이벤트 상세
			path: '/event',
			component: () => import(`@/views/desktop/single-page/EventPage`),
		},
		{
			// 이벤트 상세
			path: '/m/event',
			component: () => import(`@/views/mobile/single-page/MobEventPage`),
		},
		{
			// 이벤트 상세
			path: '/review',
			name: 'review',
			component: () => import(`@/views/desktop/single-page/ReviewPage`),
		},
		{
			// 이벤트 상세
			path: '/m/review',
			name: 'mreview',
			component: () => import(`@/views/mobile/single-page/MobReviewPage`),
		},
		{
			// 개인정보처리방침
			path: '/privacy',
			component: () => import(`@/views/desktop/single-page/PrivacyPage`),
		},
		{
			// 개인정보처리방침
			path: '/m/privacy',
			component: () => import(`@/views/desktop/single-page/PrivacyPage`),
		},
		{
			// 이용약관
			path: '/policy',
			component: () => import(`@/views/desktop/single-page/PolicyPage`),
		},

		{
			// 이용약관
			path: '/m/policy',
			component: () => import(`@/views/desktop/single-page/PolicyPage`),
		},
	],
});

// 라우터 네이게이션 가드
// // 라우터에 설정해놓은 meta.auth 와 로그인한 정보가 없을시 login page 로 이동
router.beforeEach(async (to, from, next) => {
	window.onpopstate = () => {
		if (store.state.commonModule.findIdModalActive) {
			store.state.commonModule.findIdModalActive = false;
		}
	};
	// url이 모달주소일 경우 redirect
	if (store.state.deviceType === 'MOBILE' && to.hash.indexOf('#modal') > -1) {
		next('/m/login');
	}
	if (
		store.state.deviceType === 'MOBILE' &&
		to.path !== '/m' &&
		(to.path === '/' || to.path.indexOf('/m/') < 0)
	) {
		next('/m' + to.fullPath);
	}
	// 로그인 한 정보가 있을시 /login url 이동하면 logout 기능요청 후 /login 으로 리다이렉트
	if (
		(to.path === '/login' || to.path === '/m/login') &&
		store.getters.isLogin
	) {
		await store.dispatch('LOGOUT', 'Y');
	}
	if (
		(to.path === '/sign-up' || to.path === '/m/sign-up') &&
		store.getters.isLogin
	) {
		await store.dispatch('LOGOUT', 'Y');
	}
	if (
		!store.getters.isLogin &&
		(to.path === '/mypage' ||
			to.path === '/m/mypage' ||
			to.path === '/check-pwd' ||
			to.path === '/m/check-pwd' ||
			to.path === '/personal-info' ||
			to.path === '/m/personal-info' ||
			to.path === '/review' ||
			to.path === '/m/review')
	) {
		await store.commit('redirectLogin');
	}
	await store.dispatch('commonModule/getMallLogo');
	next();
	// store.commit('setSubDomain');
});
router.afterEach(() => {
	store.commit('setPath');
	scrollMainToTop();
});
