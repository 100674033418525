import VDataIterator from './VDataIterator'
import VDataFooter from './VDataFooter'

export { VDataIterator, VDataFooter }
export default {
  $_vuetify_subcomponents: {
    VDataIterator,
    VDataFooter,
  },
}
