import { instance, instanceNS } from '../index.js';
import { jsonToQueryString } from '../../common/common';

const domain = 'MallManagement/MallBanner/';


// 몰 이벤트 조회
const getStoreMallBannerList = async data => {
	let param = '';
	if (data) param = jsonToQueryString(data);
	const response = await instance.get(`${domain}getStoreMallBannerList${param}`);
	return response;
};

export {
	getStoreMallBannerList,
};
