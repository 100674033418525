import Vue from 'vue';
import Vuex from 'vuex';
import { getStoreMallEventList, getStoreMallEvent } from '../../api/mall/EventApi';

Vue.use(Vuex);

const EventModule = {
	namespaced: true,
	state: {
		pagingData: {
			pageEndNo: 1,
			pageLastNo: 1,
			pageNo: 1,
			pageNumList: [1],
			perPageCnt: 20,
		},
		filterData: {
			perPageCnt: 4,
			pageNo: 1,
		},
		totalCnt: 0,
		mallEventList: [],
	},
	mutations: {
		setStoreMallEventList(state, result) {
			state.mallEventList = result.resultList || [];
			state.totalCnt = result.totalCnt || 0;
			state.pagingData.perPageCnt = result.perPageCnt || 0;
			state.pagingData.pageNo = result.pageNo || 0;
			state.pagingData.pageNumList = result.pageNumList || [];
			state.pagingData.pageLastNo = result.pageLastNo || 0;
			state.pagingData.pageEndNo = result.pageEndNo || 0;
		},
		setStoreMallEventListInit(state) {
			state.mallEventList = [];
		},
	},
	actions: {
		async getStoreMallEventList({ commit }, data) { // 몰 이벤트 조회
			try {
				commit('setStoreMallEventListInit');
				const result = await getStoreMallEventList(data);
				if (result) {
					commit('setStoreMallEventList', result.data.data);
				}
			} catch (e) {
				console.log(e);
			}
		},
		async getStoreMallEvent({ commit }, data) { // 몰 이벤트 상세 조회
			try {
				const result = await getStoreMallEvent(data);
				if (result && '0000' === result.data.resultCode) {
					return result.data.data;
				} else {
					return false;
				}
			} catch (e) {
				console.log(e);
			}
		},
	},
};
export default EventModule;
