import Vue from 'vue';
import Vuex from 'vuex';
import {
	checkDuplicate,
	getMallTermsList,
	signup,
	getMallMember,
	updateMallMember,
	getLoginId,
	verify,
	updatePassword,
	getMallMemberInfo,
	getGuestApplicationDetail,
} from '@/api/mall/MemberApi';

Vue.use(Vuex);

const MemberModule = {
	namespaced: true,
	state: {
		productBottomContMode: 'INFO',
		policyTermsData: { mallTermsDto: { termsContents: '' } },
		privacyTermsData: { mallTermsDto: { termsContents: '' } },
		allchecked: false,
	},
	mutations: {
		setMallTermsList(state, result) {
			if (result.length > 0) {
				for (let i in result) {
					if (result[i].mallJoinTermsType === 'PRIVACY_POLICY') {
						state.privacyTermsData = result[i];
					} else if (result[i].mallJoinTermsType === 'TERMS_OF_SERVICE') {
						state.policyTermsData = result[i];
					}
				}
			}
		},
		initMallTermsList(state) {
			state.policyTermsData = { mallTermsDto: { termsContents: '' } };
			state.privacyTermsData = { mallTermsDto: { termsContents: '' } };
		},
	},
	actions: {
		async getMallTermsList({ commit }, data) {
			try {
				// commit('initMallTermsList');
				const result = await getMallTermsList(data);
				if (result && '0000' === result.data.resultCode) {
					commit('setMallTermsList', result.data.data);
					return result.data.data;
				}
			} catch (e) {
				console.log(e);
			}
		},
		async checkDuplicate({ commit }, data) {
			try {
				const result = await checkDuplicate(data);
				if (result && '0000' === result.data.resultCode) {
					if (result.data.data === 0) {
						return true;
					} else {
						return false;
					}
					// commit('setMallTermsList', result.data.data);
				}
			} catch (e) {
				console.log(e);
			}
		},
		async signup({ commit }, data) {
			try {
				const result = await signup(data);
				if (result && '0000' === result.data.resultCode) {
					alert(result.data.resultMsg);
					return true;
				}
			} catch (e) {
				console.log(e);
			}
		},
		async getMallMember({ commit }, data) {
			try {
				const result = await getMallMember(data);
				if (result && '0000' === result.data.resultCode) {
					return result.data.data;
				} else {
					// if (result && '9000' === result.data.resultCode) {
					alert(
						'비밀번호를 잘못 입력했습니다.\n입력하신 내용을 다시 확인해주세요.',
					);
					return false;
				}
			} catch (e) {
				console.log(e);
			}
		},
		async updateMallMember({ commit }, data) {
			try {
				const result = await updateMallMember(data);
				if (result && '0000' === result.data.resultCode) {
					return true;
				} else {
					// alert('수정 실패했습니다./n 관리자에게 문의해 주세요.');
					return false;
				}
			} catch (e) {
				console.log(e);
			}
		},
		async getLoginId({ commit }, data) {
			// 아이디 찾기
			try {
				const result = await getLoginId(data);
				if (result && '0000' === result.data.resultCode) {
					return result.data.data;
				} else {
					alert(result.data.resultMsg);
					return false;
				}
			} catch (e) {
				console.log(e);
			}
		},
		async verify({ commit }, data) {
			// 비밀번호 찾기
			try {
				const result = await verify(data);
				if (result && '4104' === result.data.resultCode) {
					// alert(result.data.resultMsg);
					return true;
				} else {
					alert(result.data.resultMsg);
					return false;
				}
			} catch (e) {
				console.log(e);
			}
		},
		async updatePassword({ commit }, data) {
			// 비밀번호 재설정
			try {
				const result = await updatePassword(data);
				if (result && '0000' === result.data.resultCode) {
					alert('비밀번호가 재설정되었습니다.');
					return true;
				} else {
					alert(result.data.resultMsg);
					return false;
				}
			} catch (e) {
				console.log(e);
			}
		},
		async getGuestApplicationDetail({ commit }, data) {
			// 비회원 신청서 조회
			try {
				const result = await getGuestApplicationDetail(data);
				if (result && '0000' === result.data.resultCode) {
					return result.data.data;
				} else {
					return false;
				}
			} catch (e) {
				console.log(e);
			}
		},
	},
};
export default MemberModule;
