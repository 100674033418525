import Vue from 'vue';
import Vuex from 'vuex';
import {
	getGoodsList,
} from '@/api/mall/MainPageApi';

Vue.use(Vuex);

const BannerModule = {
	namespaced: true,
	state: {
		productList: [],
		productListFilter: {},
	},
	mutations: {
		setSample(state, result) {
			// sample Set
		},
		setSampleInit(state) {
			// sample Init
		},
	},
	actions: {
		async getGoodsList({ commit }, data) { // 리뷰 목록 조회(메인)
			try {
				const result = await getGoodsList(data);
				if (result && '0000' === result.data.resultCode) {
					return result.data.data.resultList;
				} else {
					return false;
				}
			} catch (e) {
				console.log(e);
			}
		},
	},
};
export default BannerModule;
