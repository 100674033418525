<template>
	<div class="gnb" style="">
		<div class="wrapper" :class="{ 'justify-end': nowPath !== '/' }">
			<div class="gnb_l" v-if="nowPath === '/'">
				<ul class="gnb_list">
					<li :class="{selectMaker: makerId === 0}"><a @click="getProductList(0)">전체</a></li>
					<li :class="{selectMaker: makerId === 1}"><a @click="getProductList(1)">삼성</a></li>
					<li :class="{selectMaker: makerId === 2}"><a @click="getProductList(2)">Apple</a></li>
					<li :class="{selectMaker: makerId === 9999}"><a @click="getProductList(9999)">기타</a></li>
				</ul>
			</div>
			<div class="gnb_r">
				<ul class="gnb_list">
					<template v-if="!login">
						<li>
							<router-link to="login">로그인</router-link>
						</li>
						<li>
							<router-link to="sign-up">회원가입</router-link>
						</li>
					</template>
					<template v-if="login">
						<li>
							<router-link to="/mypage">
							<span>{{ name }} 님</span>
							</router-link>
						</li>
						<li><a @click="logout">로그아웃</a></li>
					</template>
				</ul>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'GnbApp',
	data() {
		return {
			makerId: 0,
		};
	},
	computed: {
		productList: {
			get() {
				return this.$store.state.MainPageModule.productList;
			},
			set(newValue) {
				this.$store.state.MainPageModule.productList = newValue;
			},
		},
		productListFilter: {
			get() {
				return this.$store.state.MainPageModule.productListFilter;
			},
			set(newValue) {
				this.$store.state.MainPageModule.productListFilter = newValue;
			},
		},
		name() {
			return this.$store.state.cookies.name;
		},
		login() {
			return this.$store.getters.isLogin;
		},
		nowPath() {
			return this.$store.state.nowPath;
		},
		deviceType() {
			return this.$store.state.deviceType;
		},
	},
	methods: {
		async logout() {
			await this.$store.dispatch('LOGOUT', 'Y');
			await this.$router.push('/').catch(() => {});
		},
		async getProductList(makerId) {
			// let parma = {
			// 	makerCodeId: makerId,
			// };
			this.makerId = makerId;
			this.productListFilter.makerCodeId = makerId;
			if(makerId === 0) {
				delete this.productListFilter.makerCodeId;
			}
			let response = await this.$store.dispatch(
				'MainPageModule/getGoodsList',
					this.productListFilter,
			);
			this.productList = response;

			let targetY = document.getElementById('productList').offsetTop - 20;
			document.querySelector('html').scrollTo({
				top: targetY,
				behavior: 'smooth',
			});
		},
	},
	async created() {},
	beforeDestroy() {},
	mounted() {},
};
</script>
<style scoped>
.gnb {
	border-top: 1px solid var(--main-bordercolor-darkgray);
	border-bottom: 1px solid var(--main-bordercolor-darkgray);
	top: 68px !important;
}

.gnb_r {
}

.gnb .wrapper {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.gnb_list li {
	float: left;
	padding: 0;
}

.gnb_list li span,
.gnb_r .gnb_list li a {
	font-size: 14px;
	/*font-weight: 600;*/
}

.gnb_list li a {
	padding: 8px 0;
	font-size: 18px;
}

.gnb_l .gnb_list li {
	margin-right: 32px;
}

.gnb_r .gnb_list li {
	margin-left: 24px;
}

.selectMaker {
	border-bottom: 2px solid #000;
}
</style>
