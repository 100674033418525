import Vue from 'vue';
import App from './App.vue';
import store from './store/index.js';
import { router } from './router/index.js';
import VueMeta from 'vue-meta';
import vuetify from './plugins/vuetify.js';
import './plugins/fontAwesomeIcon.js';
import vueMoment from 'vue-moment';
import VueClipboard from 'vue-clipboard2';
import { VueJsonp } from 'vue-jsonp';

Vue.use(VueMeta);
Vue.use(vueMoment);
Vue.use(VueClipboard);
Vue.use(VueJsonp);

Vue.config.productionTip = false;

new Vue({
	render: h => h(App),
	store,
	vuetify,
	router,
}).$mount('#app');
