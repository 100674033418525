import Vue from 'vue';
import Vuex from 'vuex';
import { reSetInstance } from '../api/index.js';
import { getFromCookie, saveCookies, deleteCookies } from '../common/cookies';
import { unescapeHtml } from '../common/common';
import { router } from '../router/index';
import commonModule from './common/commonModule.js';
import CodeModule from './common/CodeModule.js';
import ProductDetailModule from '@/store/modules/ProductDetailModule';
import MemberModule from '@/store/modules/MemberModule';
import EventModule from '@/store/modules/EventModule';
import BannerModule from '@/store/modules/BannerModule';
import MainPageModule from '@/store/modules/MainPageModule';
import { getMallMemberInfo, login, logout } from '@/api/mall/MemberApi';
import EventReviewModule from '@/store/modules/EventReviewModule';

Vue.use(Vuex);

export default new Vuex.Store({
	state: {
		drawer: false,
		cookies: {
			name: getFromCookie('name') || '',
			mq: getFromCookie('mq') || '',
		},
		alarmData: [],
		validationFocus: null,
		nowPath: '/',
		menuName: '',
		subDomain: '',
		authToken: '',
		deviceType: 'PC', // 'MOBILE' or 'PC'
		termsCheckDialog: false,
	},
	getters: {
		isLogin(state) {
			return state.cookies.name !== '';
		},
	},
	mutations: {
		checkDeviceType(state) {
			let isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
			if (isMobile) {
				state.deviceType = 'MOBILE';
			}
			// if (
			// 	window.location.pathname.indexOf('/m/') > -1 ||
			// 	window.location.pathname.slice(-2) === '/m'
			// ) {
			// 	state.deviceType = 'MOBILE';
			// }
		},
		setSubDomain(state) {
			let firstIdx = window.location.hostname.indexOf('.');
			if (window.location.hostname.substring(firstIdx, -1) === 'www') {
				state.subDomain = 'direct';
			} else {
				state.subDomain = window.location.hostname.substring(firstIdx, -1);
			}
		},
		setAuthToken(state, data) {
			if (data) {
				state.authToken = data;
			}
			if (window.sessionStorage.getItem('authToken') === null) {
				state.authToken = '';
			} else {
				state.authToken = window.sessionStorage.getItem('authToken');
			}
		},
		setPath(state) {
			state.nowPath = window.location.pathname;
		},
		setInfo(state, data) {
			reSetInstance();
			// 로그인시 기본 쿠키정보 초기화 Start
			for (let [key] of Object.entries(state.cookies)) {
				key = '';
			}
			deleteCookies(state.cookies);
			// 로그인시 기본 쿠키정보 초기화 End
			saveCookies(data, state.cookies);
		},
		clearInfo(state) {
			for (let [key] of Object.entries(state.cookies)) {
				state.cookies[key] = '';
			}
			reSetInstance();
		},
		redirectLogin(state) {
			if (state.deviceType === 'PC') {
				router.push('/login').catch(() => {});
			} else {
				router.push('/m/login').catch(() => {});
			}
		},
	},
	actions: {
		async LOGIN({ commit }, data) {
			commit('clearInfo');
			deleteCookies(this.state.cookies);
			commit('setInfo', data);
		},
		async LOGOUT({ commit }, tokenDel) {
			if (tokenDel === 'Y') {
				// await logout();
			}
			commit('clearInfo');
			deleteCookies(this.state.cookies);
			window.sessionStorage.removeItem('authToken');
			commit('setAuthToken');
			reSetInstance();
			// commit('redirectLogin');
		},
		async login({ commit }, data) {
			try {
				const result = await login(data);
				if(result.data.resultCode === '0000') {
					window.sessionStorage.setItem(
						'authToken',
						result.headers.authorization,
					);
					commit('setAuthToken', result.headers.authorization);
				}
				return result;
			} catch (e) {
				console.log(e);
			}
		},
		async getMallMemberInfo({ commit }, data) {
			try {
				const result = await getMallMemberInfo(data);
				if (result && '0000' === result.data.resultCode) {
					return result.data.data;
				} else {
					return false;
				}
			} catch (e) {
				console.log(e);
			}
		},
	},
	modules: {
		commonModule: commonModule,
		CodeModule: CodeModule,
		MemberModule: MemberModule,
		ProductDetailModule: ProductDetailModule,
		EventModule: EventModule,
		EventReviewModule: EventReviewModule,
		BannerModule: BannerModule,
		MainPageModule: MainPageModule,
	},
});
