<template>
	<header style="z-index: 40; position: sticky; top: 0">
		<div class="header-top">
			<div class="wrapper">
				<div class="disFx align-center">
					<a @click="goMain"><h1>
							<img
								v-if="logoResponseDto.logoImagePath !== null"
								:alt="storeInfoResponseDto.mallTitle"
								:src="
									logoResponseDto.domainUrl + logoResponseDto.logoImagePath ||
									''
								"
							/>
							<span v-if="logoResponseDto.logoImagePath === null">
								{{ storeInfoResponseDto.mallTitle }}
							</span>
						</h1>
					</a>
				</div>
				<div class="main-search disFx">
					<div class="v-select-telecom mr-1" style="width: 82px; z-index: 1;">
						<v-select
								v-model="searchTelecom"
								:items="openingTelecomItemList"
								item-value="value"
								outlined
								@change="changeSearchTelecom($event)"
								attach
								:nudge-right="140"
								:nudge-top="200"
						>
							<template v-slot:selection="{ item }">
								<img v-if="item.src" :src="item.src" />
								<span v-else>전체</span>
							</template>
							<template v-slot:item="{ item }">
								<img v-if="item.src" :src="item.src" />
								<span v-else>전체</span>
							</template>
						</v-select>
					</div>
					<input
						type="text"
						placeholder="갤럭시 S22"
						v-model.trim="searchGroupName"
						style="height: 38px;"
						@keyup.enter="getProductList()"
					/>
					<button
							@click="getProductList()">
						<img
							class="header_search"
							src="../../src/assets/img/search.png"
							alt=""
						/>
					</button>
				</div>
				<div class="disFx align-center">
					<div class="v-select-telecom" style="width: 72px; height: 37px !important; margin-right: 5px;">
						<v-select
							:items="deviceTelecom"
							item-text="name"
							item-value="name"
							v-model="nowTelecom"
							@change="existTelecomCodeChange"
							outlined
						>
							<template v-slot:selection="{ item }">
								<img style="width: 25px;" :src="item.image" />
								<!--                {{ item.name }}-->
							</template>
							<template v-slot:item="{ item }">
								<img style="width: 25px;" :src="item.image" />
								<!--                {{ item.name }}-->
							</template>
						</v-select>
					</div>
					<router-link to="mypage">
						<img
							alt="마이페이지"
							class="my_page"
							src="../../src/assets/img/mypage.png"
						/>
					</router-link>
				</div>
			</div>
		</div>
		<gnb-app @resetSerachParam="resetSerachParam" ref="gnbApp"></gnb-app>
	</header>
</template>

<script>
import gnbApp from '@/components/GnbApp';
import bus from '../common/bus.js';

export default {
	name: 'HeaderApp',
	components: { gnbApp },
	data() {
		return {
			searchGroupName: '',
			searchTelecom: 0,
			openingTelecomItemList: [
				{value: 0,},
				{
					value: 5,
					src: require('../assets/img/logo_sk.png'),
				},
				{
					value: 6,
					src: require('../assets/img/logo_kt.png'),
				},
				{
					value: 7,
					src: require('../assets/img/logo_lg.png'),
				},
			],
			deviceTelecom: [
				{
					name: 5,
					image:
							require('../assets/img/logo_sk.png'),
				},
				{
					name: 6,
					image:
							require('../assets/img/logo_kt.png'),
				},
				{
					name: 7,
					image:
							require('../assets/img/logo_lg.png'),
				},
				{
					name: 1,
					image:
							require('../assets/img/logo_save1.png'),
				},
				{
					name: 2,
					image:
							require('../assets/img/logo_new.png'),
				},
			],
		};
	},
	computed: {
		productList: {
			get() {
				return this.$store.state.MainPageModule.productList;
			},
			set(newValue) {
				this.$store.state.MainPageModule.productList = newValue;
			},
		},
		productListFilter: {
			get() {
				return this.$store.state.MainPageModule.productListFilter;
			},
			set(newValue) {
				this.$store.state.MainPageModule.productListFilter = newValue;
			},
		},
		deviceType() {
			return this.$store.state.deviceType;
		},
		logoResponseDto() {
			return this.$store.state.commonModule.mallInfo.logoResponseDto;
		},
		storeInfoResponseDto() {
			return this.$store.state.commonModule.mallInfo.storeInfoResponseDto;
		},
		nowTelecom: {
			get() {
				return this.$store.state.commonModule.nowTelecom;
			},
			set(newValue) {
				this.$store.state.commonModule.nowTelecom = newValue;
			},
		},
	},
	methods: {
		async getProductList() {
			let param = {
				goodsName: this.searchGroupName,
			};
			if(this.searchTelecom !== 0) {
				param.telecomCodeId = this.searchTelecom;
				this.productListFilter.telecomCodeId = this.searchTelecom;
			}
			this.productListFilter.goodsName = this.searchGroupName;
			if(this.$route.path !== '/') {
				await this.$router.push({ name: 'desktopMain', params: param });
			} else {
				let response = await this.$store.dispatch(
						'MainPageModule/getGoodsList',
						this.productListFilter,
				);
				this.productList = response;
				let targetY = document.getElementById('productList').offsetTop - 20;
				document.querySelector('html').scrollTo({
					top: targetY,
					behavior: 'smooth',
				});
			}
		},
		changeSearchTelecom(e) {
			if (e === 0) {
				delete this.productListFilter.telecomCodeId;
			}
			this.getProductList();
		},
		async goMain() {
			this.searchGroupName = '';
			this.searchTelecom = 0;
			this.$refs.gnbApp.makerId = 0;
			if(this.$route.path !== '/') {
				let param = {};
				await this.$router.push({ name: 'desktopMain', params: param });
			} else {
				let param = {};
				let response = await this.$store.dispatch(
						'MainPageModule/getGoodsList',
						param,
				);
				this.productList = response;
				let targetY = document.getElementById('productList').offsetTop - 20;
				document.querySelector('html').scrollTo({
					top: targetY,
					behavior: 'smooth',
				});
			}
		},
		async resetSerachParam() {
			delete this.productListFilter.telecomCodeId;
			delete this.productListFilter.goodsName;
			this.searchGroupName = '';
			this.searchTelecom = 0;
		},
		async existTelecomCodeChange() {
			this.productListFilter.existTelecomCodeId = this.nowTelecom;
			if(this.$route.path === '/' || this.$route.path === '/m') {
				let response = await this.$store.dispatch('MainPageModule/getGoodsList', this.productListFilter);
				this.productList = response;
			}
			if(this.$route.path === '/product') {
				bus.$emit('existTelSet');
			}
		},
	},
	async created() {},
	beforeDestroy() {},
	mounted() {},
	watch: {
		$route(to, from) {},
	},
};
</script>
<style scoped>
header {
	display: flex;
	flex-direction: column;
	background: #fff;
	height: 118px;
}

.header-top {
	width: 100%;
	/*border-bottom: 1px solid var(--main-bordercolor-darkgray);*/
}

.header-top .wrapper {
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 68px;
}

h1 span {
	font-size: 24px;
}
</style>
