import { instance, instanceNS } from '../index.js';
import { jsonToQueryString } from '../../common/common';

const domain = 'MallManagement/MallEvent/';


// 몰 이벤트 조회
const getStoreMallEventList = async data => {
	let param = '';
	if (data) param = jsonToQueryString(data);
	const response = await instance.get(`${domain}getStoreMallEventList${param}`);
	return response;
};

// 몰 이벤트 상세 조회
const getStoreMallEvent = async data => {
	let param = '';
	if (data) param = jsonToQueryString(data);
	const response = await instance.get(`${domain}getStoreMallEvent${param}`);
	return response;
};

export {
	getStoreMallEventList,
	getStoreMallEvent,
};
