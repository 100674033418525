<template>
	<v-app id="app">
		<template>
			<header-app v-if="deviceType === 'PC'"></header-app>
			<mob-header-app v-if="deviceType === 'MOBILE'"></mob-header-app>
			<div id="main-content">
				<v-main id="mainBox">
					<v-container>
						<router-view></router-view>
						<floating-app v-if="pathCheck()"></floating-app>
					</v-container>
				</v-main>
				<footer-app
					v-if="
						nowPath.indexOf('/m/product') < 0 && nowPath.indexOf('/m/order') < 0
					"
				></footer-app>
			</div>
		</template>
		<TelecomModal></TelecomModal>
	</v-app>
</template>

<script>
import HeaderApp from './components/HeaderApp.vue';
import FooterApp from './components/FooterApp.vue';
import FloatingApp from './components/FloatingApp.vue';
import TelecomModal from './components/modal/TelecomModal.vue';
import MobHeaderApp from '@/components/MobHeaderApp';

export default {
	// <head>들어가는 title, description, keyword는 여기서 관리
	metaInfo() {
		return {
			title: this.title,
			meta: [
				{ vmid: 'description', name: 'description', content: this.description },
				{ vmid: 'keywords', name: 'keywords', content: this.keywords },
				{
					vmid: 'og:title',
					property: 'og:title',
					content: this.title,
				},
				{
					vmid: 'og:description',
					property: 'og:description',
					content: this.description,
				},
				{ vmid: 'og:image', property: 'og:image', content: this.logoUrl },
			],
		};
	},
	components: {
		MobHeaderApp,
		TelecomModal,
		HeaderApp,
		FooterApp,
		FloatingApp,
	},
	data() {
		return {
			title: '',
			description: '',
			keywords: '',
		};
	},
	computed: {
		nowPath() {
			return this.$store.state.nowPath;
		},
		deviceType() {
			return this.$store.state.deviceType;
		},
		mallInfo() {
			return this.$store.state.commonModule.mallInfo;
		},
		storeInfoResponseDto() {
			return this.$store.state.commonModule.mallInfo.storeInfoResponseDto;
		},
		dialogTelecom: {
			get() {
				return this.$store.state.commonModule.telecomModalActive;
			},
			set(newValue) {
				this.$store.state.commonModule.telecomModalActive = newValue;
			},
		},
		nowTelecom: {
			get() {
				return this.$store.state.commonModule.nowTelecom;
			},
			set(newValue) {
				this.$store.state.commonModule.nowTelecom = newValue;
			},
		},
		productListFilter: {
			get() {
				return this.$store.state.MainPageModule.productListFilter;
			},
			set(newValue) {
				this.$store.state.MainPageModule.productListFilter = newValue;
			},
		},
		existTelecomCodeId: {
			get() {
				return this.$store.state.MainPageModule.productListFilter.existTelecomCodeId;
			},
			set(newValue) {
				this.$store.state.MainPageModule.productListFilter.existTelecomCodeId = newValue;
			},
		},
	},
	methods: {
		async getMallLogo() {
			await this.$store.dispatch('commonModule/getMallLogo');
		},
		pathCheck() {
			if (this.deviceType === 'MOBILE'
					&& this.$route.path !== '/m/product'
					&& this.$route.path !== '/m/order'
					&& this.$route.path !== '/m/login'
					&& this.$route.path !== '/m/sign-up') {
				return true;
			} else {
				return false;
			}
		},
		async setMain() {
			this.title = this.mallInfo.storeInfoResponseDto.mallTitle;
			this.keywords = this.mallInfo.storeInfoResponseDto.mallKeywords;
			this.description = this.mallInfo.storeInfoResponseDto.mallDescription;
			this.logoUrl =
					this.mallInfo.logoResponseDto.domainUrl +
					this.mallInfo.logoResponseDto.logoImagePath;
		}
	},
	async beforeMount() {
		let sessNowTelecom = window.sessionStorage.getItem('authToken');
		if(sessNowTelecom) {
			this.nowTelecom = sessNowTelecom;
		}
		if(this.nowTelecom === 0) {
			this.dialogTelecom = true;
		} else {
			this.productListFilter.existTelecomCodeId = this.nowTelecom;
		}

	},
	created() {
		this.nowTelecom = 6;
	},
	beforeDestroy() {},
	watch: {
		async $route(to, from) {
			await this.setMain();
		},
		nowTelecom: {
			deep: true,
			handler: function (newVal) {
				window.sessionStorage.setItem(
						'newTelecom',
						newVal,
				);
			},
		},
	},
};
</script>

<style>
@import 'assets/css/vuetifyCustom.css';
@import 'assets/css/reset.css';
@import 'assets/css/main.css';
@import 'assets/css/desktop.css';
@import 'assets/css/mobile.css';
@import '~vue2-editor/dist/vue2-editor.css';
@import '~quill/dist/quill.snow.css';
</style>
