import { commonCodeEnumList, commonCodeList } from '../api/common/common';
import { dataCodeName } from './enum';
import { number } from 'sockjs-client/lib/utils/random';
import index from '../store/index.js';
import CryptoJS from 'crypto-js/aes.js';

// formData validation
// formData 의 모든 프로퍼티 null check
// 상단 dataCodeName 와 연계해서 alert 메세지

// nullCheck 안할 파라미터만 빼고 체크
export const nullValidation = (formData, noneValidation) => {
	if (!noneValidation) {
		noneValidation = [];
	}
	for (let data in formData) {
		if (!formData[data] && !noneValidation.includes(data)) {
			// 값이
			alert(dataCodeName[data] + ' 을(를) 입력해 주세요.');
			return false;
		}
	}
	return true;
};
// nullCheck 할 파라미터만 체크
export const nullValidation2 = (formData, validation) => {
	for (let data in formData) {
		if (!formData[data] && validation.includes(data)) {
			alert(dataCodeName[data] + ' 을(를) 입력해 주세요.');
			index.state.validationFocus = data;
			return false;
		}
	}
	return true;
};
// nullCheck 할 파라미터만 체크
export const nullValidation3 = (formData, validation) => {
	for (let i in validation) {
		if (formData[validation[i]] === '' || formData[validation[i]] === 0) {
			alert(dataCodeName[validation[i]] + ' 을(를) 입력해 주세요.');
			index.state.validationFocus = validation[i];
			// console.log(validation[i]);
			return false;
		}
	}
	return true;
};
// validation focus
export const focusVal = vm => {
	// index.state.validationFocus 최상의 store State에 등록됨
	// computed validationFocus 필요함
	if (vm.$refs[index.state.validationFocus]) {
		vm.$refs[index.state.validationFocus].focus();
		index.state.validationFocus = null;
	}
	// 등록인
	if (vm.$refs.appl_counselor) {
		switch (index.state.validationFocus) {
			case 'applRegiUserId':
				vm.$refs.appl_counselor.focus();
				index.state.validationFocus = null;
				break;
		}
	}
	// 사업자번호
	if (vm.$refs.bizNum) {
		switch (index.state.validationFocus) {
			case 'bizNum1':
				vm.$refs.bizNum.focus();
				index.state.validationFocus = null;
				break;
			case 'bizNum2':
				vm.$refs.bizNum.focus();
				index.state.validationFocus = null;
				break;
			case 'bizNum3':
				vm.$refs.bizNum.focus();
				index.state.validationFocus = null;
				break;
		}
	}
	// 사업자 휴대폰번호
	if (vm.$refs.bizPhone) {
		switch (index.state.validationFocus) {
			case 'bizPhone1':
				vm.$refs.bizPhone.focus();
				index.state.validationFocus = null;
				break;
			case 'bizPhone2':
				vm.$refs.bizPhone.focus();
				index.state.validationFocus = null;
				break;
			case 'bizPhone3':
				vm.$refs.bizPhone.focus();
				index.state.validationFocus = null;
				break;
		}
	}
	// 법인등록번호
	if (vm.$refs.bizRegiNum) {
		switch (index.state.validationFocus) {
			case 'bizRegiNum1':
				vm.$refs.bizRegiNum.focus();
				index.state.validationFocus = null;
				break;
			case 'bizRegiNum2':
				vm.$refs.bizRegiNum.focus();
				index.state.validationFocus = null;
				break;
		}
	}
	// 고객휴대폰번호
	if (vm.$refs.cusPhone) {
		switch (index.state.validationFocus) {
			case 'cusPhone1':
				vm.$refs.cusPhone.focus();
				index.state.validationFocus = null;
				break;
			case 'cusPhone2':
				vm.$refs.cusPhone.focus();
				index.state.validationFocus = null;
				break;
			case 'cusPhone3':
				vm.$refs.cusPhone.focus();
				index.state.validationFocus = null;
				break;
		}
	}
	// 법정대리인주민번호
	if (vm.$refs.courtProctorRegiNum) {
		switch (index.state.validationFocus) {
			case 'courtProctorRegiNum1':
				vm.$refs.courtProctorRegiNum.focus();
				index.state.validationFocus = null;
				break;
			case 'courtProctorRegiNum2':
				vm.$refs.courtProctorRegiNum.focus();
				index.state.validationFocus = null;
				break;
		}
	}
	// 법정대리인휴대폰번호
	if (vm.$refs.courtProctorPhone) {
		switch (index.state.validationFocus) {
			case 'courtProctorPhone1':
				vm.$refs.courtProctorPhone.focus();
				index.state.validationFocus = null;
				break;
			case 'courtProctorPhone2':
				vm.$refs.courtProctorPhone.focus();
				index.state.validationFocus = null;
				break;
			case 'courtProctorPhone3':
				vm.$refs.courtProctorPhone.focus();
				index.state.validationFocus = null;
				break;
		}
	}
	// 외국인등록번호/면허번호
	if (vm.$refs.licenseNum) {
		switch (index.state.validationFocus) {
			case 'licenseNum1':
				vm.$refs.licenseNum.focus();
				index.state.validationFocus = null;
				break;
			case 'licenseNum2':
				vm.$refs.licenseNum.focus();
				index.state.validationFocus = null;
				break;
			case 'licenseNum3':
				vm.$refs.licenseNum.focus();
				index.state.validationFocus = null;
				break;
		}
	}
	// 개통휴대폰번호
	if (vm.$refs.openingPhone) {
		switch (index.state.validationFocus) {
			case 'openingPhone1':
				vm.$refs.openingPhone.focus();
				index.state.validationFocus = null;
				break;
			case 'openingPhone2':
				vm.$refs.openingPhone.focus();
				index.state.validationFocus = null;
				break;
			case 'openingPhone3':
				vm.$refs.openingPhone.focus();
				index.state.validationFocus = null;
				break;
		}
	}
};
// email 정규식
export const validateEmail = email => {
	var re =
		/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	if (!re.test(String(email).toLowerCase())) {
		alert('이메일 형식이 맞지 않습니다. \n(e.g: abc@naver.com)');
	}
	return re.test(String(email).toLowerCase());
};

// GET call 시 queryString 변환
export const jsonToQueryString = json => {
	return (
		'?' +
		Object.keys(json)
			.map(function (key) {
				return encodeURIComponent(key) + '=' + encodeURIComponent(json[key]);
			})
			.join('&')
	);
};

// GET 코드리스트
export const commonCodeListFnc = async data => {
	try {
		const result = await commonCodeList(data);
		return result.data.data;
	} catch (e) {
		console.log(e);
	}
};

// GET ENUM 코드리스트
export const commonCodeEnumListFnc = async data => {
	try {
		const result = await commonCodeEnumList(data);
		return result.data.data;
	} catch (e) {
		console.log(e);
	}
};

// GET telecomName
export const getTelecomName = data => {
	if (data === 5) {
		return 'SKT';
	} else if (data === 6) {
		return 'KT';
	} else if (data === 7) {
		return 'LGU';
	}
};

// GET InStockStatusName
export const getInStockStatusName = data => {
	if (data === 'NORMAL') {
		return '정상';
	} else if (data === 'OPEN') {
		return '개봉';
	}
};

// GET productFaultyYnItemsName
export const getProductFaultyYnName = data => {
	if (data === 'N') {
		return '정상';
	} else if (data === 'Y') {
		return '불량';
	}
};

// GET deviceExtrrStatusName
export const getExtrrStatusName = data => {
	if (data === 'T') {
		return '상';
	} else if (data === 'M') {
		return '중';
	} else if (data === 'B') {
		return '하';
	} else if (data === 'F') {
		return '파손';
	}
};

// 날짜 버튼
export const setDate = criteria => {
	let now = new Date(); // 현재 날짜 및 시간
	let week7 = new Date(new Date().setDate(new Date().getDate() - 7)); // 일주일전
	let week15 = new Date(new Date().setDate(new Date().getDate() - 15)); // 보름 전
	let month = new Date(new Date().setMonth(new Date().getMonth() - 1)); // 한달 전
	let start_dt;
	let end_dt;
	if (criteria === 'day') {
		start_dt = getFormatDate(now);
		end_dt = getFormatDate(now);
	} else if (criteria === 'week7') {
		start_dt = getFormatDate(week7);
		end_dt = getFormatDate(now);
	} else if (criteria === 'week15') {
		start_dt = getFormatDate(week15);
		end_dt = getFormatDate(now);
	} else if (criteria === 'month') {
		start_dt = getFormatDate(month);
		end_dt = getFormatDate(now);
	} else if (criteria === 'all') {
		start_dt = '';
		end_dt = '';
	}
	let result = {
		start_dt: start_dt,
		end_dt: end_dt,
	};
	return result;
};
// 날짜 변환
export const getFormatDate = date => {
	let year = date.getFullYear(); //yyyy
	let month = 1 + date.getMonth(); //M
	month = month >= 10 ? month : '0' + month; //month 두자리로 저장
	let day = date.getDate(); //d
	day = day >= 10 ? day : '0' + day; //day 두자리로 저장
	return year + '-' + month + '-' + day;
};

export const isNumber = value => {
	// value에는 문자로된 숫자, 음수도 true
	// 실수(소수점) 체크 안됨 false
	let reg = /^[-|+]?\d+$/;
	return reg.test(value);
};

// 객체 복사
export const objectClone = data => {
	let output = [];
	for (let i in data) {
		output[i] = data[i];
	}
	return output;
};

export const copyObj = obj => {
	const result = {};
	for (let key in obj) {
		if (Array.isArray(obj[key])) {
			let arr = [];
			for (let i = 0; i < obj[key].length; i++) {
				arr.push(obj[key][i]);
			}
			result[key] = arr;
		} else if (obj[key] && typeof obj[key] === 'object') {
			result[key] = copyObj(obj[key]);
		} else {
			if (!obj[key] && obj[key] !== 0) {
				result[key] = '';
			} else if (!obj[key] && obj[key] === 0) {
				result[key] = 0;
			} else {
				result[key] = obj[key];
			}
		}
	}
	return result;
};

// 객체 비교
export const objectCompare = (modifyObj, copyObj) => {
	let result = [];
	for (let data in modifyObj) {
		if (Array.isArray(modifyObj[data])) {
		}
		//객체 안의 객체
		else if (typeof modifyObj[data] == 'object') {
			let depsObj = objectCompare(modifyObj[data], copyObj[data]); // 재귀
			// 객체안의 key 가 0개가 아닐때
			if (depsObj.length !== 0) {
				result.push(depsObj);
			}
		} else if (modifyObj[data] !== copyObj[data]) {
			let obj = {
				title: dataCodeName[data],
				value: data,
				data: copyObj[data],
				changeData: modifyObj[data],
			};
			result.push(obj);
		}
	}
	return result;
};

export const setNewDataFnc = (obj, key, value) => {
	if (Object.keys(obj).includes(key)) {
		obj[key] = value;
	} else {
		for (let key1 in obj) {
			if (
				typeof obj[key1] === 'object' &&
				Object.keys(obj[key1]).includes(key)
			) {
				obj[key1] = setNewDataFnc(obj[key1], key, value);
			}
		}
	}
	return obj;
};

export const formatToNumber = value => {
	return Number(value.replace(/,/gi, ''));
};

export const confirmFor = state => {
	let text = '';
	switch (state) {
		case 'modify':
			text = '수정하시겠습니까?';
			break;
		case 'save':
			text = '저장하시겠습니까?';
			break;
		case 'return':
			text = '변경을 취소하시겠습니까?';
			break;
	}
	return confirm(text);
};

export const alertFor = state => {
	let text = '';
	switch (state) {
		case 'noChange':
			text = '변경된 내용이 없습니다.';
			break;
		case 'successToSave':
			text = '등록되었습니다.';
			break;
		case 'error':
			text = '등록에 실패하였습니다.';
			break;
	}
	alert(text);
};

export const compareTime = Time => {
	const today = new Date();
	const timeValue = new Date(Time);

	const betweenTime = Math.floor(
		(today.getTime() - timeValue.getTime()) / 1000 / 60,
	);
	if (betweenTime < 1) return '방금전';
	if (betweenTime < 60) {
		return `${betweenTime}분전`;
	}
	const betweenTimeHour = Math.floor(betweenTime / 60);
	if (betweenTimeHour < 24) {
		return `${betweenTimeHour}시간전`;
	}

	const betweenTimeDay = Math.floor(betweenTime / 60 / 24);
	if (betweenTimeDay < 365) {
		return `${betweenTimeDay}일전`;
	}
	return `${Math.floor(betweenTimeDay / 365)}년전`;
};

export const unescapeHtml = str => {
	if (str == null) {
		return '';
	}
	return str
		.replace(/&amp;/g, '&')
		.replace(/&ldquo;/g, '"')
		.replace(/&rdquo;/g, '"')
		.replace(/&lsquo;/g, "'")
		.replace(/&rsquo;/g, "'")
		.replace(/&middot;/g, '·')
		.replace(/&lt;/g, '<')
		.replace(/&gt;/g, '>')
		.replace(/&quot;/g, '"')
		.replace(/&#039;/g, "'")
		.replace(/&#39;/g, "'");
};

export const toHtml = text => {
	if (text !== '') {
		return text
			.replace(/&amp;/g, '&')
			.replace(/&ldquo;/g, '"')
			.replace(/&rdquo;/g, '"')
			.replace(/&lsquo;/g, "'")
			.replace(/&rsquo;/g, "'")
			.replace(/&middot;/g, '·')
			.replace(/&lt;/g, '<')
			.replace(/&gt;/g, '>')
			.replace(/&quot;/g, '"')
			.replace(/\n/g, '<br/>')
			.replace(/(^[\s\u200b]*|[\s\u200b]*$)/g, '');
	}
};

export const initData = data => {
	for (let i in data) {
		if (typeof data[i] == 'object') {
			initData(data[i]);
		}
		if (typeof data[i] == 'number') {
			data[i] = 0;
		}
		if (typeof data[i] == 'string') {
			data[i] = '';
		}
	}
};

// YY-MM-DD 날짜 포맷
export const getToday = () => {
	return getDateFormat(new Date());
};

export const getDateFormat = param => {
	let date = param;
	let year = date.getFullYear();
	let month = ('0' + (1 + date.getMonth())).slice(-2);
	let day = ('0' + date.getDate()).slice(-2);

	return year + '-' + month + '-' + day;
};
// HH:MM 시간 포맷
// SS 추가 필요시 분기처리후 추가
export const getTime = () => {
	return getTimeFormat(new Date());
};
export const getTimeFormat = param => {
	let today = param;
	let hours = ('0' + today.getHours()).slice(-2);
	let minutes = ('0' + today.getMinutes()).slice(-2);
	//let seconds = ('0' + today.getSeconds()).slice(-2);
	let timeString = hours + ':' + minutes;
	return `${timeString}`;
};

export const getBirthData = (cusRegiNum1, cusRegiNum2) => {
	let n1 = Number(cusRegiNum1.substring(0, 2));
	let n2 = Number(cusRegiNum2.substring(0, 1));
	let year;
	if (n2 === 1 || n2 === 2 || n2 === 5 || n2 === 6) {
		//뒤 첫째값이 1, 2, 5, 6일 경우(1900년대에 출생한 남녀)
		year = 1900 + n1;
	}
	if (n2 === 3 || n2 === 4 || n2 === 7 || n2 === 8) {
		//뒤 첫째값이 3, 4, 7, 8일 경우(2000년대에 출생한 남녀)
		year = 2000 + n1;
	}
	let month = cusRegiNum1.substring(2, 4);
	let day = cusRegiNum1.substring(4);
	if (n2 === 0) {
		return '';
	}
	return `${String(year)}-${month}-${day}`;
};

// HH:MM 시간 포맷
//현재 시간으로부터 경과 시간
export const laterTime = (hh, mm) => {
	let hour = new Date().getHours();
	let min = new Date().getMinutes();
	let hoursLater = hour + hh;
	if (hoursLater >= 24) {
		hoursLater = hoursLater - 24;
	}
	let hoursText = ('0' + hoursLater).slice(-2);
	let timeString;
	if (mm) {
		let minutesLater = min + mm;
		if (minutesLater >= 60) {
			minutesLater = minutesLater - 60;
		}
		let minutesText = ('0' + minutesLater).slice(-2);
		timeString = hoursText + ':' + minutesText;
	} else {
		let minutesLater = min;
		if (minutesLater >= 60) {
			minutesLater = minutesLater - 60;
		}
		let minutesText = ('0' + minutesLater).slice(-2);
		timeString = hoursText + ':' + minutesText;
	}
	return `${timeString}`;
};
export const initPagingData = pagingData => {
	pagingData.pageNo = 1;
	pagingData.pageLastNo = 0;
	pagingData.pageEndNo = 1;
	pagingData.perPageCnt = 20;
	pagingData.pageNumList = [1];
};
export const scrollMainToTop = () => {
	// document.getElementById('main-content').scrollTo(0, 0);
	document.querySelector('html').scrollTo(0, 0);
};

export const findAExtractBfromC = (A_key, A_value, B_key, C_arr) => {
	return C_arr[C_arr.findIndex(obj => obj[A_key] === A_value)][B_key];
};
// 주민번호 미성년자 검사
export const minorsValidationFnc = (regiNum1, regiNum2) => {
	let jumin1 = regiNum1; // 주민번호 앞
	let jumin2 = regiNum2; // 주민번호 뒤
	let curDateObj = new Date();
	let curDate = ''; // 현재일자
	let tmpAge = 0; // 임시나이
	let curYear = curDateObj.getFullYear(); // 현재년도
	let curMonth = curDateObj.getMonth() + 1; // 현재월
	if (curMonth < 10) curMonth = '0' + curMonth;
	let curDay = curDateObj.getDate(); // 현재일
	if (curDay < 10) curDay = '0' + curDay;
	let genType = jumin2.substring(0, 1);

	if (genType <= 2) {
		tmpAge = curYear - (1900 + parseInt(jumin1.substring(0, 2))); // 1,2 일 경우
	} else if (genType > 4) {
		return alert('유효하지 않은 주민번호 입니다.');
	} else {
		tmpAge = curYear - (2000 + parseInt(jumin1.substring(0, 2))); // 그외의경우
	}

	let tmpBirthDay = jumin1.substring(2, 6); // 주민번호 4자리 생일문자 추출

	if (curDate < curYear + tmpBirthDay) {
		tmpAge += 1;
	}

	let alertTxt;

	if (tmpAge <= 19) {
		alertTxt = '미성년자 입니다.';
	} else if (tmpAge >= 100) {
		alertTxt = '100세 이상입니다.';
	}

	return alertTxt;
};
// 주민번호 유효성검사
export const regiNumValFnc = regiNum => {
	// 주민번호 변경 구제 제도에 포함되는 사람은 검증이 적용되지 않는다.
	// 2020년 개정된 주민번호체계의 검증코드에 포함되는 사람은 검증이 적용되지 않는다.
	let total = 0;
	const jumin = regiNum.replace('-', '').split('');
	const lastNum = parseInt(jumin[jumin.length - 1]);
	for (let i = 0; i < jumin.length; i++) {
		if (0 <= i && i < 8) {
			total += parseInt(jumin[i]) * (i + 2);
		} else if (7 < i && i < 12) {
			total += parseInt(jumin[i]) * (i - 6);
		} else {
			continue;
		}
	}
	const checkNum = (11 - (total % 11)) % 10;
	return lastNum === checkNum
		? '유효한 주민번호 입니다.'
		: '유효하지 않은 주민번호 입니다.';
};

export const ch2pattern = ch => {
	const offset = 44032;
	if (/[가-힣]/.test(ch)) {
		const chCode = ch.charCodeAt(0) - offset;
		if (chCode % 28 > 0) {
			return ch;
		}
		const begin = Math.floor(chCode / 28) * 28 + offset;
		const end = begin + 27;
		return `[\\u${begin.toString(16)}-\\u${end.toString(16)}]`;
	}
	if (/[ㄱ-ㅎ]/.test(ch)) {
		const con2syl = {
			ㄱ: '가'.charCodeAt(0),
			ㄲ: '까'.charCodeAt(0),
			ㄴ: '나'.charCodeAt(0),
			ㄷ: '다'.charCodeAt(0),
			ㄸ: '따'.charCodeAt(0),
			ㄹ: '라'.charCodeAt(0),
			ㅁ: '마'.charCodeAt(0),
			ㅂ: '바'.charCodeAt(0),
			ㅃ: '빠'.charCodeAt(0),
			ㅅ: '사'.charCodeAt(0),
		};
		const begin =
			con2syl[ch] || (ch.charCodeAt(0) - 12613) * 588 + con2syl['ㅅ'];
		const end = begin + 587;
		return `[${ch}\\u${begin.toString(16)}-\\u${end.toString(16)}]`;
	}

	const reRegExpChar = /[\\^$.*+?()[\]{}|]/g;
	const reHasRegExpChar = RegExp(reRegExpChar.source);

	function escapeRegExp(string) {
		return string && reHasRegExpChar.test(string)
			? string.replace(reRegExpChar, '\\$&')
			: string || '';
	}

	// lodash
	return escapeRegExp(ch);
};

export const enCrypt = plainText => {
	const key = 'dRgUkXp2s5v8y/B?';
	const encrypted = CryptoJS.encrypt(plainText, key).toString();
	return encrypted;
};
