import { instance, instanceNS } from '../index.js';
import { jsonToQueryString } from '../../common/common';

const domainGoods = 'MallManagement/GoodsMgmt/';


// 몰 이벤트 조회
const getGoodsList = async data => {
	let param = '';
	if (data) param = jsonToQueryString(data);
	const response = await instance.get(`${domainGoods}getGoodsList${param}`);
	return response;
};

export {
	getGoodsList,
};
