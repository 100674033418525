<template>
	<header style="z-index: 40; position: sticky; top: 0">
		<div class="header-top">
			<div class="disFx align-center">
				<a @click="goMain">
					<h1>
						<img
							v-if="logoResponseDto.logoImagePath !== null"
							:alt="storeInfoResponseDto.mallTitle"
							:src="
								logoResponseDto.domainUrl + logoResponseDto.logoImagePath || ''
							"
						/>
						<span v-if="logoResponseDto.logoImagePath === null">
							{{ storeInfoResponseDto.mallTitle }}
						</span>
					</h1>
				</a>
			</div>
			<div class="main-search disFx">
				<div class="v-select-telecom mr-1" style="width: 68px; z-index: 1; margin-left: 5px;">
					<v-select
							v-model="searchTelecom"
							:items="openingTelecomItemList"
							item-value="value"
							outlined
							@change="changeSearchTelecom($event)"
							attach
					>
						<template v-slot:selection="{ item }">
							<img v-if="item.src" :src="item.src" />
							<span style="font-size: 12px;" v-else>전체</span>
						</template>
						<template v-slot:item="{ item }">
							<img v-if="item.src" :src="item.src" />
							<span style="font-size: 12px;" v-else>전체</span>
						</template>
					</v-select>
				</div>
				<input
					type="text"
					style="width: 144px; height: 36px;"
					placeholder="갤럭시 S22"
					v-model.trim="searchGroupName"
					@keyup.enter="getProductList()"
				/>
				<button
						@click="getProductList()">
					<img
						class="header_search"
						src="../../src/assets/img/search.png"
						alt=""
					/>
				</button>
			</div>
<!--			<div class="disFx align-center">-->
<!--				<router-link to="/m/mypage">-->
<!--					<img-->
<!--						alt="마이페이지"-->
<!--						class="my_page"-->
<!--						src="../../src/assets/img/mypage.png"-->
<!--					/>-->
<!--				</router-link>-->
<!--			</div>-->
		</div>
		<mob-gnb-app @resetSerachParam="resetSerachParam" ref="modGnbApp"></mob-gnb-app>
	</header>
</template>

<script>
import mobGnbApp from '@/components/MobGnbApp';

export default {
	name: 'MobHeaderApp',
	components: { mobGnbApp },
	data() {
		return {
			searchGroupName: '',
			searchTelecom: 0,
			openingTelecomItemList: [
				{value: 0,},
				{
					value: 5,
					src: require('../assets/img/logo_sk.png'),
				},
				{
					value: 6,
					src: require('../assets/img/logo_kt.png'),
				},
				{
					value: 7,
					src: require('../assets/img/logo_lg.png'),
				},
			],
			deviceTelecom: [
				{
					name: 5,
					image:
						'https://www.gravatar.com/avatar/b17065ea1655f1e3283aac8d8fc16019?s=48&d=identicon&r=PG',
				},
				{
					name: 6,
					image:
						'https://www.gravatar.com/avatar/b17065ea1655f1e3283aac8d8fc16019?s=48&d=identicon&r=PG',
				},
				{
					name: 7,
					image:
						'https://www.gravatar.com/avatar/b17065ea1655f1e3283aac8d8fc16019?s=48&d=identicon&r=PG',
				},
				{
					name: 1,
					image:
						'https://www.gravatar.com/avatar/b17065ea1655f1e3283aac8d8fc16019?s=48&d=identicon&r=PG',
				},
			],
		};
	},
	computed: {
		productList: {
			get() {
				return this.$store.state.MainPageModule.productList;
			},
			set(newValue) {
				this.$store.state.MainPageModule.productList = newValue;
			},
		},
		productListFilter: {
			get() {
				return this.$store.state.MainPageModule.productListFilter;
			},
			set(newValue) {
				this.$store.state.MainPageModule.productListFilter = newValue;
			},
		},
		deviceType() {
			return this.$store.state.deviceType;
		},
		logoResponseDto() {
			return this.$store.state.commonModule.mallInfo.logoResponseDto;
		},
		storeInfoResponseDto() {
			return this.$store.state.commonModule.mallInfo.storeInfoResponseDto;
		},
	},
	methods: {
		async getProductList() {
			let param = {
				goodsName: this.searchGroupName,
			};
			if(this.searchTelecom !== 0) {
				param.telecomCodeId = this.searchTelecom;
				this.productListFilter.telecomCodeId = this.searchTelecom;
			}
			this.productListFilter.goodsName = this.searchGroupName;
			if(this.$route.path !== '/m') {
				await this.$router.push({ name: 'mobileMain', params: param });
			} else {
				let response = await this.$store.dispatch(
						'MainPageModule/getGoodsList',
						this.productListFilter,
				);
				this.productList = response;
				let targetY = document.querySelector('.products').offsetTop - 10;
				document.querySelector('html').scrollTo({
					top: targetY,
					behavior: 'smooth',
				});
			}
		},
		changeSearchTelecom(e) {
			if (e === 0) {
				delete this.productListFilter.telecomCodeId;
			}
			this.getProductList();
		},
		async goMain() {
			this.searchGroupName = '';
			this.searchTelecom = 0;
			this.$refs.modGnbApp.makerId = 0;
			if(this.$route.path !== '/m') {
				let param = {};
				await this.$router.push({ name: 'mobileMain', params: param });
			} else {
				let param = {};
				let response = await this.$store.dispatch(
						'MainPageModule/getGoodsList',
						param,
				);
				this.productList = response;
				let targetY = document.querySelector('.products').offsetTop - 10;
				document.querySelector('html').scrollTo({
					top: targetY,
					behavior: 'smooth',
				});
			}
		},
		resetSerachParam() {
			delete this.productListFilter.telecomCodeId;
			delete this.productListFilter.goodsName;
			this.searchGroupName = '';
			this.searchTelecom = 0;
		},
	},
	async created() {},
	beforeDestroy() {},
	mounted() {},
	watch: {
		$route(to, from) {},
	},
};
</script>
<style scoped>
header {
	display: flex;
	flex-direction: column;
	background: #fff;
	height: 118px;
}

.header-top {
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 68px;
	width: 100%;
	padding: 12px;
}

@media all and (max-width: 320px) {
	h1 img {
		width: 90px;
		height: 23px;
	}
}
</style>
