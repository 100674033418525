<template>
	<div class="gnb">
		<div
			v-if="nowPath === '/m'"
			:class="{
				'justify-end': nowPath !== '/m',
			}"
			class="wrapper"
		>
			<div v-if="nowPath === '/m'" class="gnb_l">
				<ul class="gnb_list">
					<li :class="{selectMaker: makerId === 0}"><a @click="getProductList(0)">전체</a></li>
					<li :class="{selectMaker: makerId === 1}"><a @click="getProductList(1)">삼성</a></li>
					<li :class="{selectMaker: makerId === 2}"><a @click="getProductList(2)">Apple</a></li>
					<li :class="{selectMaker: makerId === 9999}"><a @click="getProductList(9999)">기타</a></li>
				</ul>
			</div>
			<div class="gnb_r">
				<ul class="gnb_list">
					<template v-if="!login">
						<li>
							<router-link to="/m/login">로그인</router-link>
						</li>
						<li>
							<router-link to="/m/sign-up">회원가입</router-link>
						</li>
					</template>
					<template v-if="login">
						<li>
							<router-link to="/m/mypage">
								<span>{{ name }} 님</span>
							</router-link>
						</li>
						<li><a @click="logout">로그아웃</a></li>
					</template>
				</ul>
			</div>
		</div>
		<div v-if="nowPath !== '/m'" class="wrapper justify-center">
			<div style="position: absolute; left: 16px">
				<img src="../assets/img/icon_arrow_back.png" alt="" @click="goBack" />
			</div>
			<div class="menuTitle">{{ menuName }}</div>

			<div class="disFx" style="position: absolute; right: 12px" v-if="nowPath.indexOf('/m/product') >= 0 || nowPath.indexOf('/m/order') >= 0">
				<div class="disFx justify-center" style="margin-right: 10px;"
						 :class="nowTelecom === 1 ? 'groupBoxSave' : 'groupBox'"
						 @click="dialogTelecom = true;">
					<div class="telImg" v-if="nowTelecom === 5">
						<img src="../../src/assets/img/logo_sk.png" alt="SKT" />
					</div>
					<div class="telImg" v-else-if="nowTelecom === 6">
						<img src="../../src/assets/img/logo_kt.png" alt="KT" />
					</div>
					<div class="telImg" v-else-if="nowTelecom === 7">
						<img src="../../src/assets/img/logo_lg.png" alt="LGU" />
					</div>
					<div class="telImg imgSave" v-else-if="nowTelecom === 1">
						<img src="../../src/assets/img/logo_save1.png" alt="SAVE" />
					</div>
					<div class="telImg" v-else>
						<img src="../../src/assets/img/logo_new.png" alt="NEW" />
					</div>
				</div>
				<div class="groupBox">
					<a href="tel:1833-9413">
						<div class="disFx justify-center">
							<i class="fas fa-phone text" style="font-size: 22px;"></i>
						</div>
					</a>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { logout } from '@/api/mall/MemberApi';

export default {
	name: 'MobGnbApp',
	data() {
		return {
			makerId: 0,
		};
	},
	computed: {
		productList: {
			get() {
				return this.$store.state.MainPageModule.productList;
			},
			set(newValue) {
				this.$store.state.MainPageModule.productList = newValue;
			},
		},
		productListFilter: {
			get() {
				return this.$store.state.MainPageModule.productListFilter;
			},
			set(newValue) {
				this.$store.state.MainPageModule.productListFilter = newValue;
			},
		},
		name: {
			get() {
				return this.$store.state.cookies.name;
			},
			set(newValue) {
				this.$store.state.cookies.name = newValue;
			},
		},
		login() {
			return this.$store.getters.isLogin;
		},
		menuName() {
			return this.$store.state.menuName;
		},
		nowPath() {
			return this.$store.state.nowPath;
		},
		nowTelecom: {
			get() {
				return this.$store.state.commonModule.nowTelecom;
			},
			set(newValue) {
				this.$store.state.commonModule.nowTelecom = newValue;
			},
		},
		dialogTelecom: {
			get() {
				return this.$store.state.commonModule.telecomModalActive;
			},
			set(newValue) {
				this.$store.state.commonModule.telecomModalActive = newValue;
			},
		},
	},
	methods: {
		goBack() {
			this.$router.go(-1);
		},
		async logout() {
			await this.$store.dispatch('LOGOUT');
			await this.$router.push('/m').catch(() => {});
			await logout();
		},
		async getProductList(makerId) {
			// let parma = {
			// 	makerCodeId: makerId,
			// };
			this.makerId = makerId;
			this.productListFilter.makerCodeId = makerId;
			if(makerId === 0) {
				delete this.productListFilter.makerCodeId;
			}
			let response = await this.$store.dispatch(
				'MainPageModule/getGoodsList',
					this.productListFilter,
			);
			this.productList = response;
			let targetY = document.querySelector('.products').offsetTop - 10;
			document.querySelector('html').scrollTo({
				top: targetY,
				behavior: 'smooth',
			});
		},
	},
};
</script>
<style scoped>
.gnb {
	border-bottom: 1px solid var(--main-bordercolor-darkgray);
	top: 66px !important;
}

.menuTitle {
	font-size: 18px;
	font-weight: 600;
}

.gnb_r {
}

.gnb .wrapper {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.gnb_list li {
	float: left;
	padding: 0;
}

.gnb_list li span,
.gnb_r .gnb_list li a {
	font-size: 14px;
	/*font-weight: 600;*/
}

.gnb_list li a {
	padding: 8px 0;
	font-size: 16px;
}

.gnb_l .gnb_list li {
	margin-right: 32px;
}

.gnb_r .gnb_list li {
	margin-left: 24px;
}

.gnb_list li a {
}

@media all and (max-width: 479px) {
	.gnb_l .gnb_list li {
		margin-right: 12px;
	}

	.gnb_r .gnb_list li {
		margin-left: 12px;
	}

	.gnb_r .gnb_list li:first-of-type {
		margin-left: 0;
	}

	.gnb_list li a {
		font-size: 14px;
	}
}

@media all and (max-width: 360px) {
	.gnb_list li a {
		font-size: 13px !important;
	}
}
.groupBox {
	margin-bottom: 8px;
	padding: 8px;
	border-radius: 20px;
	box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
	background: #fff;
}

.groupBoxSave {
	margin-bottom: 8px;
	border-radius: 20px;
	box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
	background: #fff;
}

.groupBox .telImg img {
	width: 25px;
	height: 25px;
}

.groupBoxSave .imgSave img {
	border-radius: 20px;
	width: 41px !important;
	height: 41px !important;
}

.selectMaker {
	border-bottom: 2px solid #000;
}
</style>
