import Vue from 'vue';
import Vuex from 'vuex';
import {
	insertApplication,
	insertStoreCustomer,
	getApplicationDetail,
	getMyMallReview,
	getApplicationList,
	getReviewEventCheck,
	getGoodsDetail,
	// getPubNotiInfo,
	getTelecomChargeList,
	cancelApplication,
} from '@/api/mall/ProductDetailApi';

Vue.use(Vuex);

const ProductDetailModule = {
	namespaced: true,
	state: {
		productBottomContMode: 'INFO',
		privacyAgree: false,
		policyAgree: false,
		activeProductDrawer: false,
		addtionalDiscountAmt: 0,
		selectedColor: {},
		selectItemList: {
			capacityItemList: [],
			goodsColorList: [],
			discountAmt: [
				{ name: '공시지원', value: 'PUBNOTI_SUPAMT' },
				{ name: '선택약정', value: 'CHOICE_AGRMN' },
			],
			paymentType: [
				{ name: '일시불', value: 'CASH' },
				{ name: '24개월', value: 'MONTH_24' },
				{ name: '30개월', value: 'MONTH_30' },
				{ name: '36개월', value: 'MONTH_36' },
			],
		},
		selectedCharge: {
			chargeId: 0,
			chargeName: '',
			chargeAmt: 0,
			category: '',
			telecom: 0,
			network: 0,
			originKey: null,
			chargeCode: '',
			releaseDate: '',
			regiDatetime: '',
			releaseAmt: 0,
			pubNotiId: 0,
			supportAmt: 0,
			voiceAmtDetail: '',
			smsAmtDetail: '',
			dataAmtDetail: '',
		},
		formData: {
			basic: {
				openingStoreId: 21,
				parentHierarchy: '/140/2/',
				parentSaleStoreId: 140,
				applType: 'DVC',
			},
			mallMemberRequestDto: {
				memberName: '',
				loginId: '',
				memberPhone: '',
				memberPhone1: '',
				memberPhone2: '',
				memberPhone3: '',
				privacyPolicyId: 0,
				termsOfServiceId: 0,
			},
			customer: {
				cusName: '', // 이름
				cusEmail: 'yunaz0402@damacorp.co.kr', // 이메일
				cusPhone: '', // 연락처
				cusPhone1: '', // 연락처1
				cusPhone2: '', // 연락처2
				cusPhone3: '', // 연락처3
				cusRegiNum1: '', // 생년월일 **
				cusZipCode: '', // 우편번호
				cusAddr: '', // 기본주소
				cusAddrDetail: '', // 상세주소
			},
			join: {
				pubNotiId: 0,
				goodsId: 0,
				goodsName: '',
				capacity: '',
				color: '', // 받는 param으로 없음
				chargeId: 0,
				chargeName: '', //chargeIdString 로 수정?
				chargeAmt: 0,
				joinType: '',
				agreementType: 'PUBNOTI_SUPAMT', // 약정유형 (공시/선택)
				agreementTypeMsg: '공시지원', // 약정유형 (공시/선택)
				agreementPeriodType: 'MONTH_24', // 약정기간타입
				instlPeriodType: 'MONTH_24', // 할부기간타입(구매방법)
				existTelecomCodeId: 0,
				openingTelecomCodeId: 0,
				openingHopeNum: '', // 개통희망번호
				openingPhone: '',
				openingPhone1: '',
				openingPhone2: '',
				openingPhone3: '',
				numChangeAuthType: '', // 번호이동인증타입
				numChangeAuthTypeNum: '', // 번호이동인정번호
				releaseAmt: 0,
				pubNotiSupportAmt: 0,
				etcDiscountAmt: 0,
			},
			delivery: {
				deliveryZipCode: '', // 우편번호
				deliveryAddr: '', // 기본주소
				deliveryAddrDetail: '', // 상세주소
			},
			sellApplicationRequestDto: {
				sellGoodsMainInfoId: 0,
				sellRuleId: 0,
				sellRuleGoodsSupId: 0,
				sellGoodsStoreMarginInfoId: 0,
			},
		},
		etcInquiry: '',
		colorImgUrl: '',
		additionalDiscountYn: 'N',
		monthlyInterest: 0.0049167,
		allChecked: false,
	},
	mutations: {
		initJoinFormData(state) {
			for (let i in state.formData.join) {
				if (
					i !== 'agreementType' &&
					i !== 'agreementTypeMsg' &&
					i !== 'agreementPeriodType' &&
					i !== 'instlPeriodType'
				) {
					if (typeof state.formData.join[i] === 'number') {
						state.formData.join[i] = 0;
					} else if (typeof state.formData.join[i] === 'string') {
						state.formData.join[i] = '';
					}
				}
			}
			state.formData.join.agreementType = 'PUBNOTI_SUPAMT';
			state.formData.join.agreementTypeMsg = '공시지원';
			state.formData.join.agreementPeriodType = 'MONTH_24';
			state.formData.join.instlPeriodType = 'MONTH_24';
			state.additionalDiscountYn = 'N';
		},
		initOrderFormData(state) {
			for (let i in state.formData.mallMemberRequestDto) {
				if (i === 'privacyPolicyId' || i === 'termsOfServiceId') {
					state.formData.mallMemberRequestDto[i] = 0;
				} else {
					state.formData.mallMemberRequestDto[i] = '';
				}
			}
			for (let i in state.formData.customer) {
				state.formData.customer[i] = '';
			}
			for (let i in state.formData.delivery) {
				state.formData.delivery[i] = '';
			}
			state.formData.join.numChangeAuthType = '';
			state.formData.join.numChangeAuthTypeNum = '';
			state.formData.join.openingHopeNum = '';
			state.formData.join.openingPhone = '';
			state.formData.join.openingPhone1 = '';
			state.formData.join.openingPhone2 = '';
			state.formData.join.openingPhone3 = '';
			state.privacyAgree = false;
			state.policyAgree = false;
			state.etcInquiry = '';
		},
		setGoodsData(state, result) {
			state.formData.join.goodsName = result.mainInfoDto.goodsGroupName;
			if (result.marginDto !== null) {
				state.additionalDiscountYn = 'Y';
				state.formData.sellApplicationRequestDto.sellGoodsStoreMarginInfoId =
					result.marginDto.sellGoodsStoreMarginInfoId;
			}
		},
	},
	actions: {
		async insertApplication({ commit }, data) {
			try {
				const result = await insertApplication(data);
				if (result && '0000' === result.data.resultCode) {
					return result.data.data;
				}
			} catch (e) {
				console.log(e);
			}
		},
		async insertStoreCustomer({ commit }, data) {
			try {
				const result = await insertStoreCustomer(data);
				if (result && '0000' === result.data.resultCode) {
					return true;
				}
			} catch (e) {
				console.log(e);
			}
		},
		async getApplicationDetail({ commit }, data) {
			try {
				const result = await getApplicationDetail(data);
				if (result && '0000' === result.data.resultCode) {
					return result.data.data;
				}
			} catch (e) {
				console.log(e);
			}
		},
		async getMyMallReview({ commit }, data) {
			try {
				const result = await getMyMallReview(data);
				if (result && '0000' === result.data.resultCode) {
					return result.data.data;
				}
			} catch (e) {
				console.log(e);
			}
		},
		async getReviewEventCheck({ commit }, data) {
			try {
				const result = await getReviewEventCheck(data);
				if (result && '0000' === result.data.resultCode) {
					return result.data.data;
				}
			} catch (e) {
				console.log(e);
			}
		},
		async getApplicationList({ commit }, data) {
			try {
				const result = await getApplicationList(data);
				if (result && '0000' === result.data.resultCode) {
					return result.data.data;
				}
			} catch (e) {
				console.log(e);
			}
		},
		async getGoodsDetail({ commit }, data) {
			try {
				const result = await getGoodsDetail(data);
				if (result && '0000' === result.data.resultCode) {
					commit('setGoodsData', result.data.data);
					return result.data.data;
				} else {
					return false;
				}
			} catch (e) {
				console.log(e);
			}
		},
		async getTelecomChargeList({ commit }, data) {
			try {
				const result = await getTelecomChargeList(data);
				if (result && '0000' === result.data.resultCode) {
					return result.data.data;
				} else {
					return false;
				}
			} catch (e) {
				console.log(e);
			}
		},
		// async getPubNotiInfo({ commit }, data) {
		// 	try {
		// 		const result = await getPubNotiInfo(data);
		// 		if (result && '0000' === result.data.resultCode) {
		// 			return result.data.data;
		// 		} else {
		// 			return false;
		// 		}
		// 	} catch (e) {
		// 		console.log(e);
		// 	}
		// },
		async cancelApplication({ commit }, data) {
			try {
				const result = await cancelApplication(data);
				if (result && '0000' === result.data.resultCode) {
					return result.data.data;
				}
			} catch (e) {
				console.log(e);
			}
		},
	},
};
export default ProductDetailModule;
