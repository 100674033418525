<template>
	<div class="text-center" v-if="dialog">
		<v-dialog
			:persistent="true"
			v-model="dialog" width="450"
		>
			<v-card>
				<v-card-title class="dialog_title" style="">
					<button class="modal-cross">
						<i class="fas fa-times modal_close_btn" @click="dialog = false"></i>
					</button>
					어떤 통신사를 사용하시나요?
					<!--					<h3 style="font-size: 16px; line-height: 24px; font-weight: 400">-->
					<!--						기존 통신사를 선택하시면 고객님에게<br /><span-->
					<!--							class="text-decoration-underline"-->
					<!--							style="text-underline-offset: 2px; font-weight: 500"-->
					<!--							>가장 저렴한 상품</span-->
					<!--						>을 추천해드릴 수 있어요.-->
					<!--					</h3>-->
					<button class="modal-cross">
						<i class="fas fa-times modal_close_btn" @click="dialog = false"></i>
					</button>
				</v-card-title>

				<v-card-text>
					<div class="flex-column align-center mb-5 mt-11">
						<div class="disFx mb-5">
							<div
								class="select_item_telecom ml-5 mr-5"
								:class="{ selected: nowTelecom === 5,
								 sit_mobile: deviceType === 'MOBILE'}"
								@click="selectTelecom(5)"
							>
								<input
									type="radio"
									id="nowTelecom1"
									:value="5"
									v-model="nowTelecom"
									name="nowTelecom"
								/>
								<label for="nowTelecom1">
									<img src="../../../src/assets/img/logo_sk.png" alt="SKT" />
								</label>
							</div>
							<div
								class="select_item_telecom"
								:class="{ selected: nowTelecom === 6,
								 sit_mobile: deviceType === 'MOBILE' }"
								@click="selectTelecom(6)"
							>
								<input
									type="radio"
									id="nowTelecom2"
									:value="6"
									v-model="nowTelecom"
									name="nowTelecom"
								/>
								<label for="nowTelecom2">
									<img src="../../../src/assets/img/logo_kt.png" alt="KT" />
								</label>
							</div>
							<div
								class="select_item_telecom ml-5 mr-5"
								:class="{ selected: nowTelecom === 7,
								 sit_mobile: deviceType === 'MOBILE' }"
								@click="selectTelecom(7)"
							>
								<input
									type="radio"
									id="nowTelecom3"
									:value="7"
									v-model="nowTelecom"
									name="nowTelecom"
								/>
								<label for="nowTelecom3">
									<img src="../../../src/assets/img/logo_lg.png" alt="LGU" />
								</label>
							</div>
						</div>
						<div class="disFx">
							<div
								class="select_item_telecom mr-5"
								:class="{ selected: nowTelecom === 1,
								 sit_mobile: deviceType === 'MOBILE' }"
								@click="selectTelecom(1)"
							>
								<input
									type="radio"
									id="nowTelecom4"
									:value="1"
									v-model="nowTelecom"
									name="nowTelecom"
								/>
								<label for="nowTelecom4">
									<img
										src="../../../src/assets/img/logo_save1.png"
										alt="알뜰폰"
										:style="`width: ${deviceType === 'MOBILE' ? 62 : 80}px; border-radius: 10px;`"
									/>
								</label>
							</div>
							<div class="disFx">
								<div
									class="select_item_telecom mr-5"
									:class="{ selected: nowTelecom === 2,
								 sit_mobile: deviceType === 'MOBILE' }"
									@click="selectTelecom(2)"
								>
									<input
										type="radio"
										id="nowTelecom5"
										:value="2"
										v-model="nowTelecom"
										name="nowTelecom"
									/>
									<label for="nowTelecom5" style="font-size: 20px">
										신규
									</label>
								</div>
							</div>
						</div>
					</div>
				</v-card-text>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import bus from "@/common/bus";

export default {
	name: 'TelecomModal',
	props: {
		// modalWidth: { type: Number, default: 510 },
		// modalHeight: { type: Number, default: 500 },
	},
	computed: {
		// modalSize() {
		//    return `width: ${this.modalWidth}px; height: ${this.modalHeight}px;`
		//    return `height: ${this.modalHeight}px;`
		// }
		dialog: {
			get() {
				return this.$store.state.commonModule.telecomModalActive;
			},
			set(newValue) {
				this.$store.state.commonModule.telecomModalActive = newValue;
			},
		},
		nowTelecom: {
			get() {
				return this.$store.state.commonModule.nowTelecom;
			},
			set(newValue) {
				this.$store.state.commonModule.nowTelecom = newValue;
			},
		},
		productListFilter: {
			get() {
				return this.$store.state.MainPageModule.productListFilter;
			},
			set(newValue) {
				this.$store.state.MainPageModule.productListFilter = newValue;
			},
		},
		productList: {
			get() {
				return this.$store.state.MainPageModule.productList;
			},
			set(newValue) {
				this.$store.state.MainPageModule.productList = newValue;
			},
		},
		deviceType() {
			return this.$store.state.deviceType;
		},
	},
	methods: {
		async selectTelecom(telecom) {
			this.nowTelecom = telecom;
				this.dialog = false;
				this.productListFilter.existTelecomCodeId = telecom;
				if(this.$route.path === '/' || this.$route.path === '/m') {
					let response = await this.$store.dispatch('MainPageModule/getGoodsList', this.productListFilter);
					this.productList = response;
				}

			if(this.$route.path === '/m/product') {
				bus.$emit('existTelSet');
			}
		},
	},
	created() {
	},
};
</script>

<style scoped>
.modal h3 {
	color: white;
	font-weight: 400 !important;
	font-size: 18px;
	padding: 30px 48px 24px 48px;
}

.modal-cross {
	position: absolute;
	right: 20px;
	top: 8px;
	font-size: 22px;
	color: #fff;
}

.select_item_telecom {
	display: flex;
	width: 120px;
	height: 120px;
	border: 1px solid lightgray;
	border-radius: 100px;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}
.sit_mobile {
	width: 90px !important;
	height: 90px !important;
}

.select_item_telecom.selected {
	border: 3px solid #007ef2;
}

.select_item_telecom input[type='radio'] {
	display: none;
}

.select_item_telecom img {
	width: 40px;
	cursor: pointer;
}
</style>
