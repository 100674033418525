import { instance, instanceNS } from '../index.js';
import { jsonToQueryString } from '../../common/common';

const domain = 'user/';

// 회원가입시 약관 ID 조회
const getMallTermsList = async data => {
	let param = '';
	if (data) param = jsonToQueryString(data);
	const response = await instance.get(
		`MallManagement/MallTerms/getMallTermsList${param}`,
	);
	return response;
};

// 로그인 중복체크
const checkDuplicate = async data => {
	let param = '';
	if (data) param = jsonToQueryString(data);
	const response = await instance.get(`${domain}checkDuplicate${param}`);
	return response;
};

// 회원가입
const signup = async data => {
	const response = await instance.post(`${domain}signup`, data);
	return response;
};

// 로그인
const login = async data => {
	const response = await instance.post(`${domain}login`, data);
	return response;
};

// 로그아웃
const logout = async data => {
	const response = await instance.post(`${domain}invalidate`, data);
	return response;
};

// 개인정보 수정을 위한 비밀번호 검증

const getMallMember = async data => {
	let param = '';
	if (data) param = jsonToQueryString(data);
	const response = await instance.post(`${domain}getMallMember${param}`);
	return response;
};

// 회원정보수정
const updateMallMember = async data => {
	const response = await instance.post(`${domain}updateMallMember`, data);
	return response;
};

// 사용자 정보
const getMallMemberInfo = async data => {
	let param = '';
	if (data) param = jsonToQueryString(data);
	const response = await instance.get(`${domain}getMallMemberInfo${param}`);
	return response;
};

// 아이디 찾기
const getLoginId = async data => {
	const response = await instance.post(`${domain}getLoginId`, data);
	return response;
};

// 비밀번호 찾기
const verify = async data => {
	const response = await instance.post(`${domain}verify`, data);
	return response;
};

// 비밀번호 재설정
const updatePassword = async data => {
	const response = await instance.post(`${domain}updatePassword`, data);
	return response;
};

// 비회원(Guest) 신청서 상세
const getGuestApplicationDetail = async data => {
	const response = await instance.post(`/ApplicationManagement/ApplicationMgmt/getGuestApplicationDetail`, data);
	return response;
};

export {
	getMallMemberInfo,
	getMallTermsList,
	checkDuplicate,
	signup,
	login,
	logout,
	getMallMember,
	updateMallMember,
	getLoginId,
	verify,
	updatePassword,
	getGuestApplicationDetail,
};
