import Vue from 'vue';
import Vuex from 'vuex';
import { commonCodeListFnc } from '../../common/common';

Vue.use(Vuex);

const CodeModule = {
	namespaced: false,
	state: {
		codeList: {
			deviceList: [],
			colorList: [],
			capacityList: [],
			chargeList: [],
			// Month
			monthList: [
				{ name: '1월', value: 1 },
				{ name: '2월', value: 2 },
				{ name: '3월', value: 3 },
				{ name: '4월', value: 4 },
				{ name: '5월', value: 5 },
				{ name: '6월', value: 6 },
				{ name: '7월', value: 7 },
				{ name: '8월', value: 8 },
				{ name: '9월', value: 9 },
				{ name: '10월', value: 10 },
				{ name: '11월', value: 11 },
				{ name: '12월', value: 12 },
			],
			deliveryStatus: [
				// {name: '-', value: 'NONE'},
				{ name: '배송중', value: 'PROGRESS' },
				{ name: '배송완료', value: 'COMPL' },
			],
			ynList: [
				{ name: '전체', value: '' },
				{ name: '유', value: 'Y' },
				{ name: '무', value: 'N' },
			],
		},
	},
	mutations: {},
	actions: {},
};

export default CodeModule;
