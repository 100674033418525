<template>
	<footer class="w100P" :style="footerBottom">
		<div class="w100P borderTopDark">
			<div
				class="wrapper footer_top"
				:class="{ footerPad: deviceType === 'MOBILE' }"
			>
				<ul>
					<li>
						<router-link :to="privacyUrl">개인정보처리방침</router-link>
					</li>
					<li>
						<router-link :to="policyUrl">이용약관</router-link>
					</li>
				</ul>
			</div>
			<div
				class="w100P footer_bottom borderTopDark"
				:class="{ footerPad: deviceType === 'MOBILE' }"
			>
				<div class="wrapper">
					<div class="disFx">
						<div class="footer_bottom_l">
							<h3>{{ infoResponseDto.storeName }}</h3>
							<div
								class="showInMobTab preAgreeMark pl-2"
								v-html="toHtml(ictmarketPopUrl)"
							></div>
						</div>
						<div class="footer_bottom_r">
							<div>
								<div>
									<p>대표자명: {{ infoResponseDto.ceoName }}</p>
									<p>
										대표번호 :
										{{ mallPhoneCounselNum }}
									</p>
								</div>
								<div class="disFx">
									<span>사업자등록번호 : {{ bizNumFormat }}</span>
									<span
										>통신판매업신고:
										{{ storeInfoResponseDto.telecomSalesNum }}</span
									>
									<span
										>주소 : {{ infoResponseDto.storeAddr }}
										{{ infoResponseDto.storeAddrDetail }}</span
									>
								</div>
								<div
									v-if="
										infoResponseDto.storeEmail !== null &&
										infoResponseDto.storeEmail.length > 0
									"
								>
									<span>E-mail: {{ infoResponseDto.storeEmail }}</span>
								</div>
								<div class="copyright">
									Copyright &copy; 2021 1000lima.,Ltd All Rights Reserved.
								</div>
							</div>
							<div
								class="showInPc preAgreeMark"
								v-html="toHtml(ictmarketPopUrl)"
							></div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</footer>
</template>

<script>
import { toHtml } from '@/common/common';

export default {
	computed: {
		deviceType() {
			return this.$store.state.deviceType;
		},
		nowPath() {
			return this.$store.state.nowPath;
		},
		infoResponseDto() {
			return this.$store.state.commonModule.mallInfo.infoResponseDto;
		},
		storeInfoResponseDto() {
			return this.$store.state.commonModule.mallInfo.storeInfoResponseDto;
		},
    mallPhoneCounselNum() {
      return this.$store.state.commonModule.mallPhoneCounselNum;
    },
		ictmarketPopUrl() {
			return this.$store.state.commonModule.mallInfo.storeInfoResponseDto
				.ictmarketPopUrl;
		},
		footerBottom() {
			// if (this.deviceType === 'MOBILE' && this.nowPath === '/m/check-pwd') {
			// 	return 'position:absolute; bottom:0';
			// } else {
			return '';
			// }
		},
		privacyUrl() {
			if (this.deviceType === 'PC') {
				return '/privacy';
			} else {
				return '/m/privacy';
			}
		},
		policyUrl() {
			if (this.deviceType === 'PC') {
				return '/policy';
			} else {
				return '/m/policy';
			}
		},
		bizNumFormat() {
			if (this.infoResponseDto.bizNum.length > 0) {
				let bizNum =
					this.infoResponseDto.bizNum.substring(0, 3) +
					'-' +
					this.infoResponseDto.bizNum.substring(3, 5) +
					'-' +
					this.infoResponseDto.bizNum.substring(5);
				return bizNum;
			} else {
				return '';
			}
		},
	},
	methods: {
		toHtml(data) {
			if (data !== null && data !== undefined) {
				return toHtml(data);
			}
		},
	},
};
</script>

<style scoped>
.footer_top {
	height: 42px;
	display: flex;
	align-items: center;
}

.footer_top ul li {
	float: left;
	margin-right: 18px;
}

.footer_top ul li a {
	font-weight: 600;
}

.footer_bottom {
	height: 156px;
	padding-top: 20px;
}

.footer_bottom_l {
	width: 180px;
}

.footer_bottom_l h3 {
	line-height: 24px;
}

.footer_bottom_r {
	display: flex;
	justify-content: space-between;
	width: 100%;
}

.footer_bottom_r div {
	flex-wrap: wrap;
	font-weight: 600;
}

.footer_bottom_r div p:first-of-type {
	float: left;
	margin-right: 12px;
}

.footer_bottom_r div span {
	margin-right: 16px;
	line-height: 24px;
	word-break: break-all;
	font-weight: 400;
	letter-spacing: -1px;
}

.copyright {
	margin-top: 30px;
	font-size: 12px;
}

.footerPad {
	padding-left: 12px;
	padding-right: 12px;
}
</style>
