import { instance, instanceAuth, instanceExcel } from '../index.js';
import { jsonToQueryString } from '../../common/common';

const commonCodeList = async data => {
	const response = await instanceAuth.post(`dataHandle/retrieveInitData`, data);
	return response;
};

/* enum 데이터콜 */
const commonCodeEnumList = async data => {
	const response = await instanceAuth.post(
		`dataHandle/retrieveInitEnumData`,
		data,
	);
	return response;
};

/* 몰 로고 & 판매점 정보 조회 */
const getMallLogo = async data => {
	let param = '';
	if (data) param = jsonToQueryString(data);
	const response = await instance.get(
		`MallManagement/MallLogo/getMallLogo${param}`,
	);
	return response;
};
export { commonCodeList, commonCodeEnumList, getMallLogo };
