import { instance, instanceNS } from '../index.js';
import { jsonToQueryString } from '../../common/common';

const domain = 'MallManagement/MallEvent/';

// 리뷰이벤트 조회
const getStoreMallReviewEvent = async data => {
	let param = '';
	if (data) param = jsonToQueryString(data);
	const response = await instance.get(
		`${domain}getStoreMallReviewEvent${param}`,
	);
	return response;
};

// 리뷰 Link URL 검증
const validateUrl = async data => {
	const response = await instance.post(
		`MallManagement/MallReview/validateUrl`,
		data,
	);
	return response;
};

// 리뷰 등록
const insertMallReview = async data => {
	const response = await instance.post(
		`MallManagement/MallReview/insertMallReview`,
		data,
	);
	return response;
};

// 리뷰 목록 조회(메인)
const getMallReviewList = async data => {
	let param = '';
	if (data) param = jsonToQueryString(data);
	const response = await instance.get(
		`MallManagement/MallReview/getMallReviewList${param}`,
	);
	return response;
};

export { getStoreMallReviewEvent, validateUrl, insertMallReview, getMallReviewList };
