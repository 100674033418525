import axios from 'axios';
import { setInterceptors, setInterceptorsNS } from './common/interceptors';
import store from '@/store';
import logger from "@fortawesome/vue-fontawesome/src/logger";

// public API
function createInstanceAuthNone() {
	store.commit('setAuthToken');
	return axios.create({
		baseURL: process.env['VUE_APP_SERVER_AUTH'],
		headers: {
			'X-SUBDOMAIN': store.state.subDomain,
			'Authorization': store.state.authToken,
		},
	});
}

// private API
function createInstanceAuth() {
	store.commit('setAuthToken');
	const instance = axios.create({
		baseURL: process.env['VUE_APP_SERVER_AUTH'],
		withCredentials: true,
		headers: {
			'X-SUBDOMAIN': store.state.subDomain,
			'Authorization': store.state.authToken,
		},
	});
	return setInterceptors(instance);
}

// private API
function createInstance() {
	store.commit('setAuthToken');
	let header = null;
	if(store.state.cookies.mq) {
		header = {
			'X-SUBDOMAIN': store.state.subDomain,
			'Authorization': store.state.authToken,
			'X-MQ': store.state.cookies.mq,
		};
	} else {
		header = {
			'X-SUBDOMAIN': store.state.subDomain,
			'Authorization': store.state.authToken,
		};
	}
	const instance = axios.create({
		baseURL: process.env['VUE_APP_SERVER'],
		withCredentials: true,
		headers: header,
	});
	return setInterceptors(instance);
}

// private API for Admin Api
function createInstanceAdmin() {
	store.commit('setAuthToken');
	const instance = axios.create({
		baseURL: process.env['VUE_APP_ADMIN'],
		withCredentials: true,
		headers: {
			'X-SUBDOMAIN': store.state.subDomain,
			'Authorization': store.state.authToken,
		},
	});
	return setInterceptors(instance);
}

// private API No Spinner
function createInstanceNS() {
	store.commit('setAuthToken');
	const instance = axios.create({
		baseURL: process.env['VUE_APP_SERVER'],
		withCredentials: true,
		headers: {
			'X-SUBDOMAIN': store.state.subDomain,
			'Authorization': store.state.authToken,
		},
	});
	return setInterceptorsNS(instance);
}

// public API
function excelInstance() {
	return axios.create({
		baseURL: process.env['VUE_APP_SERVER_AUTH'],
		responseType: 'blob',
		headers: {
			'Content-Type': 'application/json',
		},
	});
}

function jsonInstance() {
	return axios.create({
		baseURL: process.env['VUE_APP_SERVER'],
	});
}

function creditTextInstance() {
	return axios.create({
		baseURL: process.env['VUE_APP_SERVER_TEXT'],
	});
}

export function reSetInstance() {
	instanceText = creditTextInstance();
	instanceAuth = createInstanceAuth();
	instance = createInstance();
	instanceAdmin = createInstanceAdmin();
	instanceNS = createInstanceNS();
	instanceAuthNone = createInstanceAuthNone();
	instanceExcel = excelInstance();
	instanceJson = jsonInstance();
}

store.commit('setSubDomain');
store.commit('setAuthToken');
store.commit('checkDeviceType');
export let instanceText = creditTextInstance();
export let instanceAuth = createInstanceAuth();
export let instance = createInstance();
export let instanceAdmin = createInstanceAdmin();
export let instanceNS = createInstanceNS();
export let instanceAuthNone = createInstanceAuthNone();
export let instanceExcel = excelInstance();
export let instanceJson = jsonInstance();
